export const TRANSLATIONS_CA = {
    // RESPONSE MESSAGE
    UNAUTHORIZED: "No autoritzat",
    TOKEN_EXPIRED: "Sessió expirada",
    ACCOUNT_NOT_ACTIVATED: "Si us plau, activeu el vostre compte al vostre correu electrònic",
    NOT_FOUND: "Servei no trobat",
    BAD_REQUEST: "Mala petició",
    SERVICE_ERROR: "Error de servei",
    NETWORK_ERROR:
        "Ho sentim, estem fent una millora al sistema, hem incorporat noves funcions i algunes actualitzacions de seguretat, doneu-nos uns minuts més per acabar. Aviat els podreu gaudir.",
    WENT_WRONG: "S'ha produït un error, torna-ho a provar",
    BLANK_IMAGE_UPLOAD_ERROR: "La imatge en blanc no es pot carregar",
    ERROR_DUPLICATED_EMAIL: "L'usuari amb el mateix correu electrònic ja existeix",
    ERROR_DOCUMENT_NOT_SIGNED: "Document no signat",
    ERROR_DOCUMENT_NOT_FOUND: "No s'ha trobat el document",
    ERROR_DOCUMENT_DELETED: "El remitent ha suprimit el document",
    ERROR_API: "Error de l'API",
    ERROR_DOCUMENT_SIZE_LARGE: "La mida del document és massa gran",
    ERROR_FILE_SIZE_LARGE: "La mida del fitxer és massa gran",
    ERROR_NOT_READY_SIGN: "Document no preparat per signar",
    ERROR_ALREADY_IN_PROCESS: "El document ja està en procés",
    ERROR_GETTING_SIGNED_DOCUMENT: "No s'ha pogut signar el document",
    ERROR_INVALID_OTP: "OTP no vàlid",
    ERROR_DOCUMENT_ALREADY_FAILED: "L'estat del document ja ha fallat",
    ERROR_NOT_READY_OTP: "No està preparat per enviar OTP, primer creeu una sol·licitud",
    ERROR_NO_PERMISSION: "No tens permís",
    ERROR_EMAIL_NOT_EXIST: "El correu electrònic no existeix",
    ERROR_INVALID_CREDENTIAL: "El correu electrònic o la contrasenya no són vàlids",
    ERROR_WRONG_PASSWORD: "Contrasenya incorrecta",
    ERROR_IP_DIFF: "S'ha detectat un problema de seguretat",
    ERROR_USER_NOT_FOUND: "Usuari no trobat",
    ERROR_CODE_EXPIRED: "El codi de verificació ha caducat",
    ERROR_INVALID_VERIFICATION_CODE: "Codi de verificació no vàlid",
    ERROR_ALREADY_SIGNED_REJECTED: "El document ja estava signat o rebutjat",
    ERROR_NO_CERTIFICATION: "Sense certificat",
    ERROR_INVALID_GUID: "GUID no vàlid",
    ERROR_SUBSCRIPTION_EXPIRED: "La teva subscripció ha caducat",
    ERROR_NOT_ALLOWED_TO_USE_OPTION: "No podeu utilitzar aquesta opció amb el vostre pla",
    ERROR_TEMPLATE_NOT_FOUND: "La plantilla no existeix",
    ERROR_NO_STRIPE_CUSTOMER: "No estàs subscrit a Stripe",
    ERROR_NO_CHAT_ADMIN: "No ets un administrador. No teniu permís per recuperar la llista d'administradors",
    ERROR_NO_STORAGE:
        "S'ha acabat el número d'emmagatzematge o de document, actualitzeu el pla o poseu-vos en contacte amb el servei d'assistència",
    ERROR_INVALID_REQUEST_DATA:
        "Les dades de la sol·licitud no són vàlides, comproveu la vostra sol·licitud i torneu-ho a provar",
    ERROR_NO_DOCUMENT_NUMBER:
        "Aquesta funció està desactivada perquè us quedeu sense signatures. Si us plau, compreu més documents o actualitzeu el vostre pla",
    ERROR_NOT_ALLOWED_TO_CREATE_SIGN_FLUX:
        "No teniu permís per crear flux de signes, poseu-vos en contacte amb el vostre administrador",
    INVALID_DOCUMENT_STATUS: "El document no està disponible per començar a signar",
    ERROR_NOT_ALLOWED_TO_REGISTER_DEVICE:
        "No podeu utilitzar la funció del dispositiu, actualitzeu el vostre pla o poseu-vos en contacte amb el servei d'assistència",
    ERROR_TABLET_NOT_FOUND: "La tauleta amb aquest identificador de dispositiu no existeix o no ets propietari",
    ERROR_NO_SIGNATURE_IMAGE: "Sense imatge de signatura",
    ERROR_DOCUMENT_DELETED_OR_SIGN_CANCELLED: "El document s'ha suprimit o el flux de signes s'ha cancel·lat",
    ERROR_NOT_ALLOWED_USE_CERTIFICATE:
        "No podeu utilitzar la funcionalitat del certificat al núvol al vostre pla, actualitzeu-lo",
    ERROR_NOT_ADMIN: "No sou administrador del compte",
    ERROR_CONTACT_NOT_FOUND: "No s'ha trobat el contacte",
    ERROR_INVALID_DISCOUNT_CODE: "Codi de descompte no vàlid",
    ERROR_NOT_DOCUMENT_OWNER: "No ets propietari d'aquest document",
    ERROR_NOT_ALLOWED_TO_USE_TEMPLATE:
        "No podeu utilitzar la funcionalitat de plantilla intel·ligent, actualitzeu el vostre pla o poseu-vos en contacte amb el servei d'assistència",
    ERROR_NOT_ALLOWED_TO_USE_SMART_FLUX:
        "No podeu utilitzar la funcionalitat de flux intel·ligent, actualitzeu el pla o poseu-vos en contacte amb el servei d'assistència",
    ERROR_NOT_DOCUMENT_SENDER: "No sou el remitent d'aquest document, no teniu permís",
    ERROR_NOT_ALLOWED_LOCAL_CERTIFICATE:
        "No puede usar la función de certificado local, actualice su plan o comuníquese con soporte",
    ERROR_SIGN_FLOW: "Flux de signes no vàlid",
    ERROR_SIGN_METHOD: "Mètode de signe no vàlid",
    ERROR_SIGN_FLOW_LEGALE:
        "No teniu permís per enviar document a un altre usuari legal, actualitzeu el vostre pla o poseu-vos en contacte amb el servei d'assistència",
    ERROR_SIGN_FLOW_APPROVE:
        "No podeu enviar un document per aprovar-lo, actualitzeu el vostre pla o poseu-vos en contacte amb el servei d'assistència",
    ERROR_SIGN_FLOW_TABLET:
        "No podeu utilitzar el flux de dispositius mòbils, actualitzeu el vostre pla o poseu-vos en contacte amb el servei d'assistència",
    ERROR_SIGNATURE_NUMBER_SMS:
        "No teniu prou número de signatura per a la signatura d'SMS remot o no teniu permís per utilitzar aquest mètode de signatura",
    ERROR_SIGNATURE_NUMBER_NO_SMS:
        "No teniu prou número de signatura per a la signatura remota o no teniu permís per utilitzar aquest mètode de signatura",
    ERROR_SIGNATURE_NUMBER_LOCAL_CERTIFICATE:
        "No teniu prou número de signatura per al signe de certificat local o no teniu permís per utilitzar aquest mètode de signatura",
    ERROR_SIGNATURE_NUMBER_CERTIFICATION:
        "No teniu prou número de signatura per a la signatura del certificat del núvol o no teniu permís per utilitzar aquest mètode de signatura",
    ERROR_SIGNATURE_NUMBER_STAMP:
        "No teniu prou número de signatura per al signe de segell o no teniu permís per utilitzar aquest mètode de signatura",
    ERROR_SIGNATURE_NUMBER_TABLET_SIGN:
        "No teniu prou número de signatura per a la signatura del dispositiu mòbil o no teniu permís per utilitzar aquest mètode de signatura",
    ERROR_SIGNATURE_NUMBER_VIDEO_VALIDATION:
        "No tens prou número per a la verificació de vídeo o no pots utilitzar aquest mètode de verificació",
    ERROR_SIGNATURE_NUMBER_SELFIE_VALIDATION:
        "No tens prou número per a la verificació Selfie o no pots utilitzar aquest mètode de verificació",
    ERROR_SIGNATURE_NUMBER_ID_VALIDATION:
        "No teniu prou número per a la verificació d'identificació o no teniu permís per utilitzar aquest mètode de verificació",
    ERROR_LEGALE_NOT_FOUND: "No existeix l'usuari legal, a qui enviareu el document per signar",
    ERROR_DEVICE_NOT_FOUND: "El dispositiu mòbil al qual enviareu el document per signar no existeix",
    ERROR_SIGNATURE_IMAGE_POSITION: "La posició de la imatge de la signatura no és vàlida",
    ERROR_NOT_ALLOWED_SECONDARY_USER:
        "No sou administrador del compte o no teniu permís per utilitzar la funció d'usuari secundari",
    ERROR_COUPON_ALREADY_USED: "Ja heu utilitzat aquest codi de descompte",
    ERROR_LINK_INVALID: "Aquest enllaç no és vàlid",
    ERROR_LINK_EXPIRED: "Aquest enllaç ha caducat",
    ERROR_NOT_OWNER: "No ets propietari",
    ERROR_CONTACT_DUPLICATED: "El contacte amb el mateix correu electrònic ja existeix",
    ERROR_DOCUMENT_SIGN_EXPIRED: "El flux de signes del document ha caducat, no podeu continuar aquest flux",
    ERROR_SMART_FORM_LINK_EXPIRED: "Aquest enllaç ha caducat i ja no està disponible",
    ERROR_FOLDER_CREATE_NOT_ALLOWED: "No teniu permís per crear una carpeta",
    ERROR_FOLDER_RENAME_NOT_ALLOWED: "No teniu permís per canviar el nom de la carpeta",
    ERROR_FOLDER_DELETE_NOT_ALLOWED: "No teniu permís per suprimir la carpeta",
    ERROR_DOCUMENT_RENAME_NOT_ALLOWED: "No teniu permís per canviar el nom del document",
    ERROR_DOCUMENT_DELETE_NOT_ALLOWED: "No teniu permís per suprimir el document",
    ERROR_NOT_AVAILABLE_TO_UPLOAD_TO_FOLDER: "No disponible per carregar el document a la carpeta actual, està ple",
    ERROR_INVALID_REMOTE_PATH: "El camí remot no és vàlid",
    ERROR_CSV_IS_NOT_MATCHED: "El format CSV no coincideix amb l'actualització",
    ERROR_DUPLICATED_FOLDER_NAME: "Nom de carpeta duplicat",
    ERROR_DOCUMENT_BROKEN: "El document està trencat o malmès, no podeu iniciar el flux de signes",

    SUCCESS_PURCHASE: "La compra es fa amb èxit",
    SUCCESS_SENT_OTP: "S'ha enviat OTP al vostre telèfon, comproveu el vostre SMS",
    SUCCESS_OTP: "OTP enviat correctament",
    SUCCESS_SUBSCRIBED: "S'ha subscrit correctament",
    SUCCESS_RESENT: "Reenvia correctament",
    SUCCESS_SIGNER_REMOVED: "El signant s'ha eliminat correctament",
    SUCCESS_DOCUMENT_SENT: "Document enviat correctament",
    SUCCESS_DOCUMENT_SENT_TO_EMAIL: "El document a signar s'ha enviat al correu electrònic",
    SUCCESS_DOCUMENT_RESENT: "El document s'ha reenviat correctament",
    SUCCESS_SIGNED: "Signat correctament",
    SUCCESS_DOCUMENT_SHARED: "El document s'ha compartit correctament",
    SUCCESS_UPDATE_PROFILE: "Actualitza el perfil correctament",
    SUCCESS_UPLOAD_USER_VIDEO: "Penja el vídeo correctament",
    SUCCESS_UPLOAD_SIGNATURE_IMAGE: "Penja la imatge de la signatura correctament",
    SUCCESS_UPLOAD_CERTIFICATION: "El certificat s'ha carregat correctament",
    SUCCESS_UPDATED_CERTIFICATION: "La contrasenya s'ha actualitzat correctament",
    SUCCESS_REMOVED_CERTIFICATION: "El certificat s'ha eliminat correctament",
    SUCCESS_UPDATED_CONFIGURATION: "Actualitza la configuració correctament",
    SUCCESS_UPDATED_PASSWORD: "Actualitza la contrasenya correctament",
    SUCCESS_ADD_SIGNATURE: "Afegiu el número de signatura correctament",
    SUCCESS_CREATED_USER: "Usuari creat correctament",
    SUCCESS_UPDATED_USER: "L'usuari s'ha actualitzat correctament",
    SUCCESS_DELETED_USER: "L'usuari s'ha suprimit correctament",
    SUCCESS_CREATED_CONTACT: "Contacte creat correctament",
    SUCCESS_UPDATED_CONTACT: "El contacte s'ha actualitzat correctament",
    SUCCESS_DELETED_CONTACT: "El contacte s'ha suprimit correctament",
    SUCCESS_DELETED_CONTACTS: "Els contactes s'han suprimit correctament",
    SUCCESS_IMPORTED_CONTACT: "El contacte s'ha importat correctament",
    SUCCESS_UPLOADED_DOCUMENT: "El document s'ha penjat correctament",
    SUCCESS_UPLOADED_FILE: "Els fitxers s'han penjat correctament",
    SUCCESS_UPDATED_DOCUMENT: "El document s'ha actualitzat correctament",
    SUCCESS_DELETED_DOCUMENT: "El document s'ha suprimit correctament",
    SUCCESS_REJECTED: "Rebutjat correctament",
    SUCCESS_UPLOADED_ATTACHED_FILE: "El fitxer adjunt s'ha penjat correctament",
    SUCCESS_DELETED_ATTACHED_FILE: "El fitxer adjunt s'ha eliminat correctament",
    SUCCESS_PAUSED_FLOW: "El flux s'ha aturat correctament",
    SUCCESS_SIGNED_DOCUMENT: "Document signat correctament",
    SUCCESS_PLAY_FLOW: "Flow ha tornat a jugar amb èxit",
    SUCCESS_SIGN_PROCESS_STARTED: "S'ha iniciat el fil de signatura",
    SUCCESS_APPROVED_DOCUMENT: "Document aprovat correctament",
    SUCCESS_APPLIED: "Aplicat correctament",
    SUCCESS_SIGN_BACKGROUND: "El procés de signatura està en segon pla, rebreu l'estat al vostre correu electrònic",
    SUCCESS_DELETED_TABLET: "La tauleta s'ha suprimit correctament",
    SUCCESS_CANCEL_SUBSCRIPTION: "La subscripció s'ha cancel·lat correctament",
    SUCCESS_CREATE_CUSTOMIZE_PLAN: "Aviat us posareu en contacte",
    SUCCESS_LINK_COPIED_TO_CLIPBOARD: "L'enllaç del formulari s'ha copiat al porta-retalls",
    SUCCESS_CSV_LINK_TEMPLATE: "El CSV s'ha enllaçat a la plantilla correctament",
    SUCCESS_CSV_WORKFLOW_UPDATE: "El flux de treball CSV s'ha actualitzat correctament",
    SUCCESS_CSV_ALARM_UPDATE: "L'alarma CSV s'ha actualitzat correctament",
    SUCCESS_CSV_WORKFLOW_LAUNCH: "El flux de treball CSV s'ha iniciat correctament",
    SUCCESS_SAVED: "S'ha desat correctament",

    CONFIRM_REMOVE_CONTACT: "Esteu segur d'eliminar aquest contacte?",
    CONFIRM_REMOVE_CONTACTS: "Esteu segur d'eliminar aquests contactes?",
    CONFIRM_SET_AS_EXTERNAL_CONTACTS: "Esteu segur de configurar aquests contactes com a usuari extern?",
    CONFIRM_APPROVE_DOCUMENT: "Esteu segur d'aprovar aquest document?",
    CONFIRM_REPLACE_VIDEO_VALIDATION: "Aquest document ja té la validació de vídeo, esteu segur de substituir-lo?",
    CONFIRM_REPLACE_SELFIE_VALIDATION: "Aquest document ja té la imatge del selfie, esteu segur que la substituïu?",
    CONFIRM_REPLACE_ID_VALIDATION: "Aquest document ja té la imatge del document, esteu segur de substituir-lo?",
    CONFIRM_REMOVE_CERTIFICATION: "Esteu segur d'eliminar aquest certificat?",
    CONFIRM_REMOVE_SIGNER: "Esteu segur d'eliminar aquest signant?",
    CONFIRM_DELETE_DOCUMENT: "Esteu segur que suprimiu aquest document?",
    CONFIRM_NOTIFICATION_MULTIPLE: "Esteu segur d'enviar una notificació a aquests signants?",
    CONFIRM_DELETE_DOCUMENT_MULTIPLE: "Esteu segur que suprimiu els documents seleccionats?",
    CONFIRM_PAUSE_DOCUMENT: "Esteu segur d'aturar el flux de signes d'aquest document?",
    CONFIRM_END_SIGN_FLOW: "Esteu segur d'acabar amb aquest flux de senyals?",
    CONFIRM_CONTINUE_FLOW: "Esteu segur de continuar amb aquest flux de senyals?",
    CONFIRM_DELETE_FILE: "Esteu segur que suprimiu aquest fitxer?",
    CONFIRM_REMOVE_USER: "Esteu segur d'eliminar aquest usuari?",
    CONFIRM_REMOVE_TABLET: "Esteu segur d'eliminar aquesta tauleta?",
    CONFIRM_REPLACE_USER_VIDEO: "Segur que substitueix el vídeo?",
    CONFIRM_MAKE_AS_ADMIN: "Esteu segur de convertir aquest usuari com a administrador?",
    CONFIRM_REMOVE_FLUX: "Esteu segur que suprimiu aquest flux?",
    CONFIRM_SEND_DOCUMENT_USE_FLUX: "Esteu segur d'enviar aquests documents amb aquest flux?",
    CONFIRM_REMOVE_TEMPLATE: "Esteu segur que suprimiu aquesta plantilla?",
    CONFIRM_DUPLICATE_TEMPLATE: "Esteu segur de duplicar aquesta plantilla?",
    CONFIRM_CANCEL_SUBSCRIPTION: "Esteu segur que cancel·leu la subscripció?",
    CONFIRM_MAKE_AS_CUSTOMIZED_PLAN: "Esteu segur d'assignar un pla a mida a aquest client?",
    CONFIRM_DELETE_FOLDER: "Esteu segur que suprimiu aquesta carpeta?",
    CONFIRM_REMOVE_FORM: "Esteu segur d'eliminar aquest formulari?",
    CONFIRM_DELETE_ALARM: "Esteu segur que suprimiu aquesta alarma?",
    CONFIRM_DELETE: "Esteu segur que suprimiu?",
    CONFIRM_LAUNCH_WORKFLOW: "Esteu segur de llançar Workflow?",
    CONFIRM_SAVE_TEMPLATE: "En desar, crearàs una nova versió, segur que ho faràs?",
    CONFIRM_LAUNCH_MULTIPLE_CSVS_WORKFLOW: "Esteu segur de llançar el flux de treball d'aquests csv?",
    CONFIRM_UNGROUP_TEMPLATES: "Esteu segur de desagrupar aquestes plantilles?",

    success: "Èxit",
    error: "Error",
    warning: "Avís",

    // LAYOUT
    footer1: "legale.io és una empresa de Despapeliza Llc. 7345 W Sand Lake RD STE 210, OFC 6837, Orlando, FL, USA",
    footer2: "Tots els drets reservats legale",
    signOut: "Tanca sessió",

    // LOGIN
    login: "Iniciar Sessió",
    email: "Correu electrònic",
    password: "Contrasenya",
    fieldRequired: "Aquest camp és obligatori",
    fieldInvalid: "Valor no vàlid",
    fileLengthError: "El nom del fitxer ha de tenir menys de {{limit}} caràcters",
    submit: "Presentar",

    // HOME
    searchDocumentByNationalIDNumber: "Busqueu el vostre document per número d'identificació nacional",
    sign: "Signe",
    noSearchResult: "No s'ha trobat cap document amb la teva informació",
    searchTip:
        "Per localitzar el document que està pendent de la vostra signatura, cal el vostre número d'identificació. Aquest identificador únic garanteix que coincidem amb exactitud amb el document correcte. Si us plau, tingueu a punt el vostre número d'identificació i introduïu-lo quan se us demani.",
    searchTipNoResult:
        "Trobeu el vostre propi document de contracte per signar amb el vostre número d'identificació nacional",
    fileName: "Nom de l'arxiu",
    subject: "Assignatura",
    description: "Descripció",
    backToSearch: "Torna a cercar",

    // SIGN
    pdfPaginationDescription: "Pàgina {{Número de pàgina}} de {{Pàgina total}}",
    signatureImage: "Imatge de signatura",
    cancel: "Cancel · lar",
    back: "Esquena",
    next: "Pròxim",
    sendCodeToMyEmail: "Envia el codi al meu correu electrònic",
    sendCodeToMyPhone: "Envia el codi al meu telèfon",
    verifyDescription:
        "Per garantir la seguretat de la vostra informació, hem de verificar la vostra identitat. Podeu optar per rebre un codi de verificació per telèfon o per correu electrònic. Seleccioneu el vostre mètode preferit.",
    selfieDescription:
        "Com a part del nostre compromís de garantir el màxim nivell de seguretat i protecció d'identificació, necessitem una última prova per confirmar la vostra identitat.",
    signatureImageDrawingTip: "No dibuixeu la vostra signatura sobre el codi QR",
    signatureImageDescription: "Si us plau, dibuixeu la imatge de la vostra signatura sobre el quadre",
    clearSignature: "Signatura clara",
    note: "Nota",
    sendCode: "Enviar codi",
    verifyCodeDescription:
        "Volem assegurar-nos que realment ets tu. Per verificar encara més la vostra identitat, introduïu el codi de verificació que s'ha enviat a {{destination}}.",
    confirm: "Confirmeu",
    resendCode: "Codi de reenviament",
    code: "Codi",
    phone: "Telèfon",
};
