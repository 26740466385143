import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Card } from "antd";

const propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    padding: PropTypes.number,
    style: PropTypes.object,
    onClick: PropTypes.func,
};

const LegaleCard = ({ children, className, contentClassName, padding, style, onClick }) => {
    return (
        <Wrapper
            className={`${className ? className : ""}`}
            padding={padding}
            style={style || {}}
            onClick={() => {
                if (onClick) onClick();
            }}
        >
            <div className={`card-content ${contentClassName || ""}`}>{children}</div>
        </Wrapper>
    );
};

const Wrapper = styled(Card)`
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.03);
    overflow: hidden;
    border: none;
    background: ${(props) => props.theme.white};

    .ant-card-body {
        height: 100%;

        padding: ${(props) => (props.padding !== undefined ? props.padding : 12)}px !important;

        .card-content {
            height: 100%;
        }
    }
`;

LegaleCard.propTypes = propTypes;

export default LegaleCard;
