import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf";
import { rgba } from "polished";

import "react-pdf/dist/Page/AnnotationLayer.css";

const propTypes = {
    url: PropTypes.string.isRequired,
    numPages: PropTypes.number,
    page: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
};

const PdfPreview = ({ url, numPages, page, setPage }) => {
    const { t } = useTranslation();

    return (
        <Wrapper className="preview">
            <div className="title">
                {t("pdfPaginationDescription", {
                    pageNumber: page,
                    totalPage: numPages,
                })}
            </div>
            <div className="previews scroll-y">
                <Document className="legale-pdf-viewer-thumbnail" loading={<div />} renderMode="canvas" file={url}>
                    {[...Array(numPages).keys()].map((i) => {
                        return (
                            <Page
                                key={i}
                                className={`legale-pdf-viewer-page ${page === i + 1 ? "current-page" : ""}`}
                                width={200}
                                pageNumber={i + 1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                renderInteractiveForms={false}
                                onClick={() => setPage(i + 1)}
                            />
                        );
                    })}
                </Document>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    box-shadow: 0 10px 20px ${(props) => rgba(props.theme.black, 0.03)};

    .title {
        color: rgba(0, 0, 0, 0.85);
        background-color: rgb(248, 230, 204);
    }

    .previews {
        .legale-pdf-viewer-thumbnail {
            .legale-pdf-viewer-page {
                min-width: auto !important;
                min-height: auto !important;

                box-shadow: 0 5px 10px ${(props) => rgba(props.theme.black, 0.05)};

                border: 1px solid transparent;
                border-radius: 6px;
                overflow: hidden;

                margin-bottom: 20px;

                &.current-page {
                    border-color: ${(props) => props.theme.orange};
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
`;

PdfPreview.propTypes = propTypes;

export default PdfPreview;
