import React from "react";
import styled from "styled-components";

import LoadingGif from "../../../assets/images/loading.gif";

const Loader = () => {
    return (
        <Wrapper>
            <img src={LoadingGif} alt="Legale Loader" />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;

    padding: 50px 0;

    display: flex;
    justify-content: center;

    img {
        width: 70px;
        height: 70px;
    }
`;

export default Loader;
