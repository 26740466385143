export const TRANSLATIONS_EN = {
    // RESPONSE MESSAGE
    UNAUTHORIZED: "Unauthorized",
    TOKEN_EXPIRED: "Session expired",
    ACCOUNT_NOT_ACTIVATED: "Please activate your account at your email",
    NOT_FOUND: "Service not found",
    BAD_REQUEST: "Bad request",
    SERVICE_ERROR: "Service error",
    NETWORK_ERROR:
        "Sorry, we are making an improvement to the system, we have incorporated new features and some security updates, give us a few more minutes to finish. Soon you will be able to enjoy them.",
    WENT_WRONG: "Something went wrong, try again",
    BLANK_IMAGE_UPLOAD_ERROR: "Blank image cannot be uploaded",
    ERROR_DUPLICATED_EMAIL: "User with same email already exists",
    ERROR_DOCUMENT_NOT_SIGNED: "Document not signed",
    ERROR_DOCUMENT_NOT_FOUND: "Document not found",
    ERROR_DOCUMENT_DELETED: "Document was deleted by the sender",
    ERROR_API: "API error",
    ERROR_DOCUMENT_SIZE_LARGE: "Document size is too large",
    ERROR_FILE_SIZE_LARGE: "File size is too large",
    ERROR_NOT_READY_SIGN: "Document not ready to sign",
    ERROR_ALREADY_IN_PROCESS: "Document is already in process",
    ERROR_GETTING_SIGNED_DOCUMENT: "Getting signed document failed",
    ERROR_INVALID_OTP: "Invalid OTP",
    ERROR_DOCUMENT_ALREADY_FAILED: "Document status was already failed",
    ERROR_NOT_READY_OTP: "Not ready to send OTP, please create a request first",
    ERROR_NO_PERMISSION: "You have no permission",
    ERROR_EMAIL_NOT_EXIST: "Email does not exist",
    ERROR_INVALID_CREDENTIAL: "Email or password are invalid",
    ERROR_WRONG_PASSWORD: "Wrong password",
    ERROR_IP_DIFF: "Security issue detected",
    ERROR_USER_NOT_FOUND: "User not found",
    ERROR_CODE_EXPIRED: "Verification code is expired",
    ERROR_INVALID_VERIFICATION_CODE: "Invalid verification code",
    ERROR_ALREADY_SIGNED_REJECTED: "Document was already signed or rejected",
    ERROR_NO_CERTIFICATION: "No certificate",
    ERROR_INVALID_GUID: "Invalid GUID",
    ERROR_SUBSCRIPTION_EXPIRED: "Your subscription is expired",
    ERROR_NOT_ALLOWED_TO_USE_OPTION: "You are not allowed to use this option with your plan",
    ERROR_TEMPLATE_NOT_FOUND: "Template does not exist",
    ERROR_NO_STRIPE_CUSTOMER: "You are not subscribed to stripe",
    ERROR_NO_CHAT_ADMIN: "You are not an admin. You have no permission to retrieve admin list",
    ERROR_NO_STORAGE: "You run out the storage or document number, please upgrade your plan or contact with support",
    ERROR_INVALID_REQUEST_DATA: "Request data is not valid, please check your request and try again",
    ERROR_NO_DOCUMENT_NUMBER:
        "This function is disabled because you run out of signatures, please buy more documents or upgrade your plan",
    ERROR_NOT_ALLOWED_TO_CREATE_SIGN_FLUX: "You are not allowed to create sign flux, please contact with your admin",
    INVALID_DOCUMENT_STATUS: "Document is unavailable to be started to sign",
    ERROR_NOT_ALLOWED_TO_REGISTER_DEVICE:
        "You are not allowed to use device function, please upgrade your plan or contact with support",
    ERROR_TABLET_NOT_FOUND: "Tablet with this device ID does not exist, or you are not owner",
    ERROR_NO_SIGNATURE_IMAGE: "No signature image",
    ERROR_DOCUMENT_DELETED_OR_SIGN_CANCELLED: "Document was deleted or sign flux was cancelled",
    ERROR_NOT_ALLOWED_USE_CERTIFICATE:
        "You are not allowed to use cloud certificate functionality in your plan, please upgrade it",
    ERROR_NOT_ADMIN: "You are not account admin",
    ERROR_CONTACT_NOT_FOUND: "Contact not found",
    ERROR_INVALID_DISCOUNT_CODE: "Invalid discount code",
    ERROR_NOT_DOCUMENT_OWNER: "You are not owner of this document",
    ERROR_NOT_ALLOWED_TO_USE_TEMPLATE:
        "You are not allowed to use smart template functionality, please upgrade your plan or contact with support",
    ERROR_NOT_ALLOWED_TO_USE_SMART_FLUX:
        "You are not allowed to use smart flux functionality, please upgrade the plan or contact with support",
    ERROR_NOT_DOCUMENT_SENDER: "You are not sender of this document, you have no permission",
    ERROR_NOT_ALLOWED_LOCAL_CERTIFICATE:
        "You are not allowed to use local certificate functionality, please upgrade your plan or contact with support",
    ERROR_SIGN_FLOW: "Invalid sign flow",
    ERROR_SIGN_METHOD: "Invalid sign method",
    ERROR_SIGN_FLOW_LEGALE:
        "You are not allowed to send document to another legale user, please upgrade your plan or contact with support",
    ERROR_SIGN_FLOW_APPROVE:
        "You are not allowed to send document to approve, please upgrade your plan or contact with support",
    ERROR_SIGN_FLOW_TABLET:
        "You are not allowed to use mobile device flux, please upgrade your plan or contact with support",
    ERROR_SIGNATURE_NUMBER_SMS:
        "You have no enough signature number for Remote SMS sign or are not allowed to use this sign method",
    ERROR_SIGNATURE_NUMBER_NO_SMS:
        "You have no enough signature number for Remote sign or are not allowed to use this sign method",
    ERROR_SIGNATURE_NUMBER_LOCAL_CERTIFICATE:
        "You have no enough signature number for Local certificate sign or are not allowed to use this sign method",
    ERROR_SIGNATURE_NUMBER_CERTIFICATION:
        "You have no enough signature number for Cloud certificate sign or are not allowed to use this sign method",
    ERROR_SIGNATURE_NUMBER_STAMP:
        "You have no enough signature number for Stamp sign or are not allowed to use this sign method",
    ERROR_SIGNATURE_NUMBER_TABLET_SIGN:
        "You have no enough signature number for Mobile device sign or are not allowed to use this sign method",
    ERROR_SIGNATURE_NUMBER_VIDEO_VALIDATION:
        "You have no enough number for Video verification or are not allowed to use this verification method",
    ERROR_SIGNATURE_NUMBER_SELFIE_VALIDATION:
        "You have no enough number for Selfie verification or are not allowed to use this verification method",
    ERROR_SIGNATURE_NUMBER_ID_VALIDATION:
        "You have no enough number for ID verification or are not allowed to use this verification method",
    ERROR_LEGALE_NOT_FOUND: "Legale user, whom you are going to send document to sign does not exist",
    ERROR_DEVICE_NOT_FOUND: "Mobile device, which you are going to send document to sign does not exist",
    ERROR_SIGNATURE_IMAGE_POSITION: "Invalid signature image position",
    ERROR_NOT_ALLOWED_SECONDARY_USER: "You are not account admin or you are not allowed to use secondary user function",
    ERROR_COUPON_ALREADY_USED: "You already used this discount code",
    ERROR_LINK_INVALID: "This link is invalid",
    ERROR_LINK_EXPIRED: "This link is expired",
    ERROR_NOT_OWNER: "You are not owner",
    ERROR_CONTACT_DUPLICATED: "Contact with same email already exists",
    ERROR_DOCUMENT_SIGN_EXPIRED: "Document sign flow is expired, you are not able to continue this flow",
    ERROR_SMART_FORM_LINK_EXPIRED: "This link has expired and is no longer available",
    ERROR_FOLDER_CREATE_NOT_ALLOWED: "You are not allowed to create folder",
    ERROR_FOLDER_RENAME_NOT_ALLOWED: "You are not allowed to rename folder",
    ERROR_FOLDER_DELETE_NOT_ALLOWED: "You are not allowed to delete folder",
    ERROR_DOCUMENT_RENAME_NOT_ALLOWED: "You are not allowed to rename document",
    ERROR_DOCUMENT_DELETE_NOT_ALLOWED: "You are not allowed to delete document",
    ERROR_NOT_AVAILABLE_TO_UPLOAD_TO_FOLDER: "Not available to upload document to current folder, it is full",
    ERROR_INVALID_REMOTE_PATH: "Remote path is invalid",
    ERROR_CSV_IS_NOT_MATCHED: "CSV format is not matched to update",
    ERROR_DUPLICATED_FOLDER_NAME: "Duplicated folder name",
    ERROR_DOCUMENT_BROKEN: "Document is broken or damaged, you can't start sign flow",

    SUCCESS_PURCHASE: "Purchase is done successfully",
    SUCCESS_SENT_OTP: "OTP was sent to your phone, please check your SMS",
    SUCCESS_OTP: "OTP sent successfully",
    SUCCESS_SUBSCRIBED: "Subscribed successfully",
    SUCCESS_RESENT: "Resent successfully",
    SUCCESS_SIGNER_REMOVED: "Signer removed successfully",
    SUCCESS_DOCUMENT_SENT: "Document sent successfully",
    SUCCESS_DOCUMENT_SENT_TO_EMAIL: "Document to be signed was sent to the email",
    SUCCESS_DOCUMENT_RESENT: "Document resent successfully",
    SUCCESS_SIGNED: "Signed successfully",
    SUCCESS_DOCUMENT_SHARED: "Document shared successfully",
    SUCCESS_UPDATE_PROFILE: "Update profile successfully",
    SUCCESS_UPLOAD_USER_VIDEO: "Upload video successfully",
    SUCCESS_UPLOAD_SIGNATURE_IMAGE: "Upload signature image successfully",
    SUCCESS_UPLOAD_CERTIFICATION: "Certificate uploaded successfully",
    SUCCESS_UPDATED_CERTIFICATION: "Password updated successfully",
    SUCCESS_REMOVED_CERTIFICATION: "Certificate removed successfully",
    SUCCESS_UPDATED_CONFIGURATION: "Update configuration successfully",
    SUCCESS_UPDATED_PASSWORD: "Update password successfully",
    SUCCESS_ADD_SIGNATURE: "Add signature number successfully",
    SUCCESS_CREATED_USER: "User created successfully",
    SUCCESS_UPDATED_USER: "User updated successfully",
    SUCCESS_DELETED_USER: "User deleted successfully",
    SUCCESS_CREATED_CONTACT: "Contact created successfully",
    SUCCESS_UPDATED_CONTACT: "Contact updated successfully",
    SUCCESS_DELETED_CONTACT: "Contact deleted successfully",
    SUCCESS_DELETED_CONTACTS: "Contacts deleted successfully",
    SUCCESS_IMPORTED_CONTACT: "Contact imported successfully",
    SUCCESS_UPLOADED_DOCUMENT: "Document uploaded successfully",
    SUCCESS_UPLOADED_FILE: "Files uploaded successfully",
    SUCCESS_UPDATED_DOCUMENT: "Document updated successfully",
    SUCCESS_DELETED_DOCUMENT: "Document deleted successfully",
    SUCCESS_REJECTED: "Rejected successfully",
    SUCCESS_UPLOADED_ATTACHED_FILE: "Attached file uploaded successfully",
    SUCCESS_DELETED_ATTACHED_FILE: "Attached file deleted successfully",
    SUCCESS_PAUSED_FLOW: "Flow paused successfully",
    SUCCESS_SIGNED_DOCUMENT: "Document signed successfully",
    SUCCESS_PLAY_FLOW: "Flow played again successfully",
    SUCCESS_SIGN_PROCESS_STARTED: "Sign thread started",
    SUCCESS_APPROVED_DOCUMENT: "Document approved successfully",
    SUCCESS_APPLIED: "Applied successfully",
    SUCCESS_SIGN_BACKGROUND: "Sign process is in background, you will receive the status through your email",
    SUCCESS_DELETED_TABLET: "Tablet deleted successfully",
    SUCCESS_CANCEL_SUBSCRIPTION: "Subscription cancelled successfully",
    SUCCESS_CREATE_CUSTOMIZE_PLAN: "You will get contact soon",
    SUCCESS_LINK_COPIED_TO_CLIPBOARD: "Form link was copied to your clipboard",
    SUCCESS_CSV_LINK_TEMPLATE: "CSV is linked to template successfully",
    SUCCESS_CSV_WORKFLOW_UPDATE: "CSV workflow is updated successfully",
    SUCCESS_CSV_ALARM_UPDATE: "CSV alarm is updated successfully",
    SUCCESS_CSV_WORKFLOW_LAUNCH: "CSV workflow is launched successfully",
    SUCCESS_SAVED: "Saved successfully",

    CONFIRM_REMOVE_CONTACT: "Are you sure to remove this contact?",
    CONFIRM_REMOVE_CONTACTS: "Are you sure to remove these contacts?",
    CONFIRM_SET_AS_EXTERNAL_CONTACTS: "Are you sure to set these contacts as external user?",
    CONFIRM_APPROVE_DOCUMENT: "Are you sure to approve this document?",
    CONFIRM_REPLACE_VIDEO_VALIDATION: "This document already has the video validation, are you sure to replace it?",
    CONFIRM_REPLACE_SELFIE_VALIDATION: "This document already has the selfie image, are you sure to replace it?",
    CONFIRM_REPLACE_ID_VALIDATION: "This document already has the document image, are you sure to replace it?",
    CONFIRM_REMOVE_CERTIFICATION: "Are you sure to remove this certification?",
    CONFIRM_REMOVE_SIGNER: "Are you sure to remove this signer?",
    CONFIRM_DELETE_DOCUMENT: "Are you sure to delete this document?",
    CONFIRM_NOTIFICATION_MULTIPLE: "Are you sure to send notification to these signers?",
    CONFIRM_DELETE_DOCUMENT_MULTIPLE: "Are you sure to delete selected documents?",
    CONFIRM_PAUSE_DOCUMENT: "Are you sure to pause the sign flow of this document?",
    CONFIRM_END_SIGN_FLOW: "Are you sure to end this sign flow?",
    CONFIRM_CONTINUE_FLOW: "Are you sure to continue this sign flow?",
    CONFIRM_DELETE_FILE: "Are you sure to delete this file?",
    CONFIRM_REMOVE_USER: "Are you sure to remove this user?",
    CONFIRM_REMOVE_TABLET: "Are you sure to remove this tablet?",
    CONFIRM_REPLACE_USER_VIDEO: "Are you sure replace the video?",
    CONFIRM_MAKE_AS_ADMIN: "Are you sure to make this user as admin?",
    CONFIRM_REMOVE_FLUX: "Are you sure to delete this flux?",
    CONFIRM_SEND_DOCUMENT_USE_FLUX: "Are you sure to send these documents using this flux?",
    CONFIRM_REMOVE_TEMPLATE: "Are you sure to delete this template?",
    CONFIRM_DUPLICATE_TEMPLATE: "Are you sure to duplicate this template?",
    CONFIRM_CANCEL_SUBSCRIPTION: "Are you sure to cancel subscription?",
    CONFIRM_MAKE_AS_CUSTOMIZED_PLAN: "Are you sure to assign tailor-made plan to this customer?",
    CONFIRM_DELETE_FOLDER: "Are you sure to delete this folder?",
    CONFIRM_REMOVE_FORM: "Are you sure to delete this form?",
    CONFIRM_DELETE_ALARM: "Are you sure to delete this alarm?",
    CONFIRM_DELETE: "Are you sure to delete?",
    CONFIRM_LAUNCH_WORKFLOW: "Are you sure to launch Workflow?",
    CONFIRM_SAVE_TEMPLATE: "By saving, you will create a new version, are you sure to do it?",
    CONFIRM_LAUNCH_MULTIPLE_CSVS_WORKFLOW: "Are you sure to launch these csvs' workflow?",
    CONFIRM_UNGROUP_TEMPLATES: "Are you sure to ungroup these templates?",

    success: "Success",
    error: "Error",
    warning: "Warning",

    // LAYOUT
    footer1: "legale.io is a Despapeliza Llc company. 7345 W Sand Lake RD STE 210, OFC 6837, Orlando, FL, USA",
    footer2: "All rights reserved legale",
    signOut: "Sign out",

    // LOGIN
    login: "Login",
    email: "Email",
    password: "Password",
    fieldRequired: "This field is required",
    fieldInvalid: "Invalid value",
    fileLengthError: "File name should be less than {{limit}} characters",
    submit: "Submit",

    // HOME
    searchDocumentByNationalIDNumber: "Search your document by national ID number",
    sign: "Sign",
    noSearchResult: "No document found with your information",
    searchTip:
        "To locate the document that is awaiting your signature, your identification number is required. This unique identifier ensures that we accurately match yu with the correct document. Please have your identification number ready and enter it when prompted.",
    searchTipNoResult: "Please find your own contract document to sign with your national ID number",
    fileName: "File Name",
    subject: "Subject",
    description: "Description",
    backToSearch: "Back to search",

    // SIGN
    pdfPaginationDescription: "Page {{pageNumber}} of {{totalPage}}",
    signatureImage: "Signature image",
    cancel: "Cancel",
    back: "Back",
    next: "Next",
    sendCodeToMyEmail: "Send code to my email",
    sendCodeToMyPhone: "Send code to my phone",
    verifyDescription:
        "To ensure the security of your information, we need to verify your identity. You can choose to receive a verification code either via phone or email. Please select your preferred method.",
    selfieDescription:
        "As part of our commitment to ensuring the highest level of security and identify protection, we require one last piece of evidence to confirm your identity.",
    signatureImageDrawingTip: "Don't draw your signature over the QR code",
    signatureImageDescription: "Please draw your signature image over the box",
    clearSignature: "Clear signature",
    note: "Note",
    sendCode: "Send code",
    verifyCodeDescription:
        "We want to make sure it's really you. In order to further verify your identity, enter the verification code that was sent to {{destination}}.",
    confirm: "Confirm",
    resendCode: "Resend code",
    code: "Code",
    phone: "Phone",
};
