import axios from "axios";

import { API_BASE_URL, SIGNING_PORTAL_AUTH_TOKEN_KEY } from "../../../utils/constants";
import * as actionTypes from "../actionTypes";
import { errorManagementHandler, updateLoading } from "./theme";

export const updateToken = (token) => {
    localStorage.setItem(SIGNING_PORTAL_AUTH_TOKEN_KEY, token);
    return {
        type: actionTypes.UPDATE_TOKEN,
        token,
    };
};

export const updateUserData = (userData) => {
    return {
        type: actionTypes.UPDATE_USER_DATA,
        userData,
    };
};

export const fetchUserData = () => {
    return (dispatch, getState) => {
        const { language } = getState().theme;
        const { token } = getState().auth;

        if (!token) {
            dispatch(logout());
        } else {
            dispatch(updateLoading(true));
            axios
                .get(`${API_BASE_URL}/profile`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Accept-Language": language,
                    },
                })
                .then((res) => {
                    const userData = res.data;
                    dispatch(updateUserData(userData));
                    dispatch(updateLoading(false));
                })
                .catch((e) => dispatch(errorManagementHandler(e)));
        }
    };
};

export const logout = () => {
    return () => {
        localStorage.removeItem(SIGNING_PORTAL_AUTH_TOKEN_KEY);
        window.open(`/login?next=${window.location.pathname}`, "_parent");
    };
};
