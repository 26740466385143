import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { rgba } from "polished";
import { Document, Page, pdfjs } from "react-pdf";
import { Rnd } from "react-rnd";

import Loader from "../../Base/Loaders/Loader";
import PdfPagination from "./Pagination";
import Toolbar from "./Components/Toolbar";
import PdfPreview from "./Components/Preview";

import { useWindowSize } from "../../../services/hook/useWindowsSize";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "./styles.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const propTypes = {
    url: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    pdfWidth: PropTypes.number.isRequired,
    pdfHeight: PropTypes.number.isRequired,
    signers: PropTypes.array,
};

const PdfReader = ({ url, fileName, pdfWidth, pdfHeight, signers }) => {
    const { t } = useTranslation();
    const documentRef = useRef();
    const signatureBoardRef = useRef();

    const [winWidth] = useWindowSize();

    const [numPages, setNumPages] = useState(0);
    const [signPositionWrapperHeight, setSignPositionWrapperHeight] = useState(0);
    const [page, setPage] = useState(1);
    const [ratio, setRatio] = useState(1);
    const [scale, setScale] = useState(1);
    const [fullScreen, setFullScreen] = useState(false);

    useEffect(() => {
        document.addEventListener("fullscreenchange", (e) => {
            if (document.fullscreenElement) {
                setFullScreen(true);
            } else {
                setFullScreen(false);
            }
        });

        return () => {
            document.removeEventListener("fullscreenchange", () => {});
        };
    }, []);

    useEffect(() => {
        // Calculate ratio in zoom, full screen
        if (winWidth) {
            let width = fullScreen ? winWidth : winWidth < 767 ? winWidth - 40 : 600;
            const ratio = pdfWidth / width;
            const height = pdfHeight / ratio + 4;
            documentRef.current.style.height = `${height}px`;
            setSignPositionWrapperHeight(height - 4);
            setRatio(ratio / scale);
        }
    }, [fullScreen, pdfWidth, pdfHeight, winWidth, scale]);

    useEffect(() => {
        // Calculate sign position and size in zoom in, zoom out
        if (signatureBoardRef.current) {
            const width = pdfWidth / ratio;
            const height = pdfHeight / ratio;
            signatureBoardRef.current.style.width = `${width}px`;
            signatureBoardRef.current.style.height = `${height}px`;
            setSignPositionWrapperHeight(height);
        }
    }, [ratio, signatureBoardRef, pdfWidth, pdfHeight]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

    const enterFullScreenHandler = (fullScreen) => {
        if (fullScreen) {
            document.documentElement.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    };

    return (
        <Wrapper className={`legale-pdf-reader-wrapper ${fullScreen ? "full-screen" : ""}`}>
            <div className="main-content">
                <Toolbar
                    page={page}
                    numPages={numPages}
                    scale={scale}
                    setScale={setScale}
                    fullScreen={fullScreen}
                    enterFullScreenHandler={enterFullScreenHandler}
                    url={url}
                    fileName={fileName}
                />
                <div className="pdf-content">
                    <Document
                        className="legale-pdf-viewer"
                        renderMode="canvas"
                        loading={
                            <div className="legale-pdf-loading">
                                <Loader />
                            </div>
                        }
                        file={url}
                        inputRef={documentRef}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page
                            className="legale-pdf-viewer-page"
                            width={fullScreen ? winWidth : winWidth > 767 ? 600 : winWidth - 40}
                            pageNumber={page}
                            renderTextLayer={false}
                            renderAnnotationLayer={true}
                            renderInteractiveForms={true}
                            scale={scale}
                        />

                        <div
                            className="signature-position-wrapper"
                            id="signature_position_wrapper"
                            ref={signatureBoardRef}
                            user-guide-step="pdf-viewer"
                        >
                            {!!signatureBoardRef.current && !!signers && signers.length
                                ? signers.map((signer, index) => {
                                      if (signer.x1 && signer.pageno === page && signer.sign_status !== 2) {
                                          const width = parseInt((signer.x2 - signer.x1) / ratio);
                                          const height = parseInt((signer.y2 - signer.y1) / ratio);
                                          const x = parseInt(signer.x1 / ratio);
                                          const y = parseInt(signPositionWrapperHeight - signer.y2 / ratio);
                                          return (
                                              <Rnd
                                                  key={index}
                                                  disableDragging={true}
                                                  enableResizing={false}
                                                  size={{
                                                      width: width,
                                                      height: height,
                                                  }}
                                                  position={{ x: x, y: y }}
                                                  bounds="parent"
                                              >
                                                  <div
                                                      className={`draggable-item signature-preview ${
                                                          height < 40 ? "small" : ""
                                                      }`}
                                                  >
                                                      {t("signatureImage")}
                                                  </div>
                                              </Rnd>
                                          );
                                      } else return null;
                                  })
                                : null}
                        </div>
                    </Document>
                </div>
            </div>
            {numPages && winWidth > 990 ? (
                <PdfPreview url={url} numPages={numPages} page={page} setPage={setPage} />
            ) : null}
            <div className="pagination-box">
                <PdfPagination numPages={numPages} pageNumber={page} setPageNumber={setPage} />
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    .main-content {
        box-shadow: 0 10px 20px ${(props) => rgba(props.theme.black, 0.03)};
    }
`;

PdfReader.propTypes = propTypes;

export default PdfReader;
