export const TRANSLATIONS_ZH = {
    // RESPONSE MESSAGE
    UNAUTHORIZED: "未经授权",
    TOKEN_EXPIRED: "会话已过期",
    ACCOUNT_NOT_ACTIVATED: "请通过您的电子邮件激活您的帐户",
    NOT_FOUND: "无法提供服务",
    BAD_REQUEST: "请求错误",
    SERVICE_ERROR: "服务错误",
    NETWORK_ERROR:
        "抱歉，我们正在对系统进行改进，我们已经加入了新功能和一些安全更新，请再给我们几分钟时间来完成。 很快你就可以享受它们了。",
    WENT_WRONG: "出了点问题，请重试",
    BLANK_IMAGE_UPLOAD_ERROR: "空白图片无法上传",
    ERROR_DUPLICATED_EMAIL: "具有相同电子邮件的用户已存在",
    ERROR_DOCUMENT_NOT_SIGNED: "文档未签名",
    ERROR_DOCUMENT_NOT_FOUND: "未找到文档",
    ERROR_DOCUMENT_DELETED: "文件已被发件人删除",
    ERROR_API: "接口错误",
    ERROR_DOCUMENT_SIZE_LARGE: "文档尺寸太大",
    ERROR_FILE_SIZE_LARGE: "文件过大",
    ERROR_NOT_READY_SIGN: "尚未准备好签名的文档",
    ERROR_ALREADY_IN_PROCESS: "文档已经在处理中",
    ERROR_GETTING_SIGNED_DOCUMENT: "获取签名文档失败",
    ERROR_INVALID_OTP: "无效OTP",
    ERROR_DOCUMENT_ALREADY_FAILED: "文档状态已经失败",
    ERROR_NOT_READY_OTP: "尚未准备好发送 OTP，请先创建一个请求",
    ERROR_NO_PERMISSION: "你没有权限",
    ERROR_EMAIL_NOT_EXIST: "电子邮件不存在",
    ERROR_INVALID_CREDENTIAL: "电子邮件或密码无效",
    ERROR_WRONG_PASSWORD: "密码错误",
    ERROR_IP_DIFF: "检测到安全问题",
    ERROR_USER_NOT_FOUND: "未找到用户",
    ERROR_CODE_EXPIRED: "验证码已过期",
    ERROR_INVALID_VERIFICATION_CODE: "无效验证码",
    ERROR_ALREADY_SIGNED_REJECTED: "文档已经签名或被拒绝",
    ERROR_NO_CERTIFICATION: "无证书",
    ERROR_INVALID_GUID: "无效 GUID",
    ERROR_SUBSCRIPTION_EXPIRED: "您的订阅已过期",
    ERROR_NOT_ALLOWED_TO_USE_OPTION: "您不得在您的计划中使用此选项",
    ERROR_TEMPLATE_NOT_FOUND: "模板不存在",
    ERROR_NO_STRIPE_CUSTOMER: "您没有订阅条纹",
    ERROR_NO_CHAT_ADMIN: "您不是管理员。 您无权检索管理员列表",
    ERROR_NO_STORAGE: "您的存储或文档编号已用完，请升级您的计划或联系支持人员",
    ERROR_INVALID_REQUEST_DATA: "请求数据无效，请检查您的请求并重试",
    ERROR_NO_DOCUMENT_NUMBER: "此功能已被禁用，因为您的签名已用完，请购买更多文件或升级您的计划",
    ERROR_NOT_ALLOWED_TO_CREATE_SIGN_FLUX: "您不允许创建标志通量，请与您的管理员联系",
    INVALID_DOCUMENT_STATUS: "文档无法开始签名",
    ERROR_NOT_ALLOWED_TO_REGISTER_DEVICE: "您不允许使用设备功能，请升级您的计划或联系支持人员",
    ERROR_TABLET_NOT_FOUND: "具有此设备 ID 的平板电脑不存在，或者您不是所有者",
    ERROR_NO_SIGNATURE_IMAGE: "没有签名图片",
    ERROR_DOCUMENT_DELETED_OR_SIGN_CANCELLED: "文档被删除或标志通量被取消",
    ERROR_NOT_ALLOWED_USE_CERTIFICATE: "您的计划中不允许使用云证书功能，请升级",
    ERROR_NOT_ADMIN: "您不是帐户管理员",
    ERROR_CONTACT_NOT_FOUND: "未找到联系人",
    ERROR_INVALID_DISCOUNT_CODE: "折扣码无效",
    ERROR_NOT_DOCUMENT_OWNER: "您不是此文档的所有者",
    ERROR_NOT_ALLOWED_TO_USE_TEMPLATE: "您不允许使用智能模板功能，请升级您的计划或联系支持",
    ERROR_NOT_ALLOWED_TO_USE_SMART_FLUX: "您不允许使用智能助焊剂功能，请升级计划或联系支持人员",
    ERROR_NOT_DOCUMENT_SENDER: "您不是此文件的发件人，您没有权限",
    ERROR_NOT_ALLOWED_LOCAL_CERTIFICATE: "您不允许使用本地证书功能，请升级您的计划或联系支持人员",
    ERROR_SIGN_FLOW: "无效的签名流",
    ERROR_SIGN_METHOD: "无效的签名方法",
    ERROR_SIGN_FLOW_LEGALE: "您不能将文件发送给其他合法用户，请升级您的计划或联系支持人员",
    ERROR_SIGN_FLOW_APPROVE: "您不允许发送文件进行批准，请升级您的计划或联系支持人员",
    ERROR_SIGN_FLOW_TABLET: "您不允许使用移动设备助焊剂，请升级您的计划或联系支持人员",
    ERROR_SIGNATURE_NUMBER_SMS: "您没有足够的签名号码进行远程短信签名或不允许使用此签名方式",
    ERROR_SIGNATURE_NUMBER_NO_SMS: "您没有足够的签名号进行远程签名或不允许使用此签名方式",
    ERROR_SIGNATURE_NUMBER_LOCAL_CERTIFICATE: "您没有足够的签名号码进行本地证书签名或不允许使用此签名方法",
    ERROR_SIGNATURE_NUMBER_CERTIFICATION: "您没有足够的签名号进行云证书签名或不允许使用此签名方式",
    ERROR_SIGNATURE_NUMBER_STAMP: "您没有足够的签名号码进行盖章签名或不允许使用此签名方法",
    ERROR_SIGNATURE_NUMBER_TABLET_SIGN: "您没有足够的签名号码用于移动设备签名或不允许使用此签名方法",
    ERROR_SIGNATURE_NUMBER_VIDEO_VALIDATION: "您没有足够的视频验证号码或不允许使用此验证方式",
    ERROR_SIGNATURE_NUMBER_SELFIE_VALIDATION: "您没有足够的号码进行自拍验证或不允许使用此验证方式",
    ERROR_SIGNATURE_NUMBER_ID_VALIDATION: "您没有足够的号码进行身份验证或不允许使用此验证方式",
    ERROR_LEGALE_NOT_FOUND: "Legale 用户，您要发送文件进行签名的用户不存在",
    ERROR_DEVICE_NOT_FOUND: "您要发送文件进行签名的移动设备不存在",
    ERROR_SIGNATURE_IMAGE_POSITION: "签名图像位置无效",
    ERROR_NOT_ALLOWED_SECONDARY_USER: "您不是帐户管理员或不允许使用辅助用户功能",
    ERROR_COUPON_ALREADY_USED: "您已使用此折扣码",
    ERROR_LINK_INVALID: "此链接无效",
    ERROR_LINK_EXPIRED: "此链接已过期",
    ERROR_NOT_OWNER: "您不是所有者",
    ERROR_CONTACT_DUPLICATED: "使用同一电子邮件地址的联系人已存在",
    ERROR_DOCUMENT_SIGN_EXPIRED: "文档签名流程已过期，您无法继续此流程",
    ERROR_SMART_FORM_LINK_EXPIRED: "此链接已过期，不再可用",
    ERROR_FOLDER_CREATE_NOT_ALLOWED: "您无权创建文件夹",
    ERROR_FOLDER_RENAME_NOT_ALLOWED: "您不无权命名文件夹",
    ERROR_FOLDER_DELETE_NOT_ALLOWED: "您无权删除文件夹",
    ERROR_DOCUMENT_RENAME_NOT_ALLOWED: "您无权重命名文件",
    ERROR_DOCUMENT_DELETE_NOT_ALLOWED: "您无权删除文件",
    ERROR_NOT_AVAILABLE_TO_UPLOAD_TO_FOLDER: "无法将文档上传到当前文件夹，已满",
    ERROR_INVALID_REMOTE_PATH: "远程路径无效",
    ERROR_CSV_IS_NOT_MATCHED: "CSV格式不匹配更新",
    ERROR_DUPLICATED_FOLDER_NAME: "文件夹名称重复",
    ERROR_DOCUMENT_BROKEN: "文件已损坏或损坏，您无法启动签名流程",

    SUCCESS_PURCHASE: "购买成功",
    SUCCESS_SENT_OTP: "OTP 已发送到您的手机，请检查您的短信",
    SUCCESS_OTP: "OTP 发送成功",
    SUCCESS_SUBSCRIBED: "订阅成功",
    SUCCESS_RESENT: "重发成功",
    SUCCESS_SIGNER_REMOVED: "签名者删除成功",
    SUCCESS_DOCUMENT_SENT: "文档发送成功",
    SUCCESS_DOCUMENT_SENT_TO_EMAIL: "要签署的文件已发送到电子邮件",
    SUCCESS_DOCUMENT_RESENT: "文档重新发送成功",
    SUCCESS_SIGNED: "签名成功",
    SUCCESS_DOCUMENT_SHARED: "文档共享成功",
    SUCCESS_UPDATE_PROFILE: "成功更新配置文档",
    SUCCESS_UPLOAD_USER_VIDEO: "视频上传成功",
    SUCCESS_UPLOAD_SIGNATURE_IMAGE: "上传签名图片成功",
    SUCCESS_UPLOAD_CERTIFICATION: "证书上传成功",
    SUCCESS_UPDATED_CERTIFICATION: "密码更新成功",
    SUCCESS_REMOVED_CERTIFICATION: "证书删除成功",
    SUCCESS_UPDATED_CONFIGURATION: "更新配置成功",
    SUCCESS_UPDATED_PASSWORD: "更新密码成功",
    SUCCESS_ADD_SIGNATURE: "买签名数量成功",
    SUCCESS_CREATED_USER: "用户创建成功",
    SUCCESS_UPDATED_USER: "用户更新成功",
    SUCCESS_DELETED_USER: "用户删除成功",
    SUCCESS_CREATED_CONTACT: "联系人创建成功",
    SUCCESS_UPDATED_CONTACT: "联系人更新成功",
    SUCCESS_DELETED_CONTACT: "联系人删除成功",
    SUCCESS_DELETED_CONTACTS: "联系人删除成功",
    SUCCESS_IMPORTED_CONTACT: "联系人导入成功",
    SUCCESS_UPLOADED_DOCUMENT: "文档上传成功",
    SUCCESS_UPLOADED_FILE: "文档上传成功",
    SUCCESS_UPDATED_DOCUMENT: "文档更新成功",
    SUCCESS_DELETED_DOCUMENT: "文档删除成功",
    SUCCESS_REJECTED: "拒绝成功",
    SUCCESS_UPLOADED_ATTACHED_FILE: "附件文档上传成功",
    SUCCESS_DELETED_ATTACHED_FILE: "附件文档删除成功",
    SUCCESS_PAUSED_FLOW: "流程暂停成功",
    SUCCESS_SIGNED_DOCUMENT: "文档签名成功",
    SUCCESS_PLAY_FLOW: "流程再次播放成功",
    SUCCESS_SIGN_PROCESS_STARTED: "签名线程开始",
    SUCCESS_APPROVED_DOCUMENT: "文档批准成功",
    SUCCESS_APPLIED: "申请成功",
    SUCCESS_SIGN_BACKGROUND: "签名过程在后台进行，您将通过邮件收到状态",
    SUCCESS_DELETED_TABLET: "平板删除成功",
    SUCCESS_CANCEL_SUBSCRIPTION: "订阅取消成功",
    SUCCESS_CREATE_CUSTOMIZE_PLAN: "您将很快取得联系",
    SUCCESS_LINK_COPIED_TO_CLIPBOARD: "表单链接已复制到您的剪贴板",
    SUCCESS_CSV_LINK_TEMPLATE: "CSV 已成功链接到模板",
    SUCCESS_CSV_WORKFLOW_UPDATE: "CSV 工作流程已成功更新",
    SUCCESS_CSV_ALARM_UPDATE: "CSV 警报已成功更新",
    SUCCESS_CSV_WORKFLOW_LAUNCH: "CSV 工作流程已成功启动",
    SUCCESS_SAVED: "保存成功",

    CONFIRM_REMOVE_CONTACT: "您确定要删除此联系人吗？",
    CONFIRM_REMOVE_CONTACTS: "您确定要删除这些联系人吗？",
    CONFIRM_SET_AS_EXTERNAL_CONTACTS: "您确定将这些联系人设置为外部用户吗？",
    CONFIRM_APPROVE_DOCUMENT: "您确定批准此文档吗？",
    CONFIRM_REPLACE_VIDEO_VALIDATION: "此文档已有视频验证，确定要替换吗？",
    CONFIRM_REPLACE_SELFIE_VALIDATION: "此文档已有自拍图像，您确定要替换吗？",
    CONFIRM_REPLACE_ID_VALIDATION: "此文档已有文档图像，您确定要替换吗？",
    CONFIRM_REMOVE_CERTIFICATION: "您确定要删除此证书吗？",
    CONFIRM_REMOVE_SIGNER: "您确定要删除此签名者吗？",
    CONFIRM_DELETE_DOCUMENT: "您确定要删除此文档吗？",
    CONFIRM_NOTIFICATION_MULTIPLE: "您确定要向这些签名者发送通知吗？",
    CONFIRM_DELETE_DOCUMENT_MULTIPLE: "您确定要删除选定的文档吗？",
    CONFIRM_PAUSE_DOCUMENT: "您确定要暂停此文档的签名流程吗？",
    CONFIRM_END_SIGN_FLOW: "你确定要结束这个签名流程吗？",
    CONFIRM_CONTINUE_FLOW: "您确定要继续此签名流程吗？",
    CONFIRM_DELETE_FILE: "您确定要删除此文档吗？",
    CONFIRM_REMOVE_USER: "您确定要删除此用户吗？",
    CONFIRM_REMOVE_TABLET: "您确定要删除此平板电脑吗？",
    CONFIRM_REPLACE_USER_VIDEO: "你确定更换视频？",
    CONFIRM_MAKE_AS_ADMIN: "您确定将此用户设为管理员吗？",
    CONFIRM_REMOVE_FLUX: "您确定要删除此工程吗？",
    CONFIRM_SEND_DOCUMENT_USE_FLUX: "您确定使用此工程发送这些文件吗？",
    CONFIRM_REMOVE_TEMPLATE: "您确定要删除此模板吗？",
    CONFIRM_DUPLICATE_TEMPLATE: "您确定复制此模板吗？",
    CONFIRM_CANCEL_SUBSCRIPTION: "您确定要取消订阅吗？",
    CONFIRM_MAKE_AS_CUSTOMIZED_PLAN: "您确定为该客户分配量身定制的计划吗？",
    CONFIRM_DELETE_FOLDER: "您确定要删除此文件夹吗？",
    CONFIRM_REMOVE_FORM: "您确定要删除此表格吗？",
    CONFIRM_DELETE_ALARM: "确定要删除这个时钟吗？",
    CONFIRM_DELETE: "确定删除吗？",
    CONFIRM_LAUNCH_WORKFLOW: "您确定要启动Workflow吗？",
    CONFIRM_SAVE_TEMPLATE: "通过保存，您将创建一个新版本，您确定要这样做吗？",
    CONFIRM_LAUNCH_MULTIPLE_CSVS_WORKFLOW: "您确定要启动这些 csv 的工作流程吗？",
    CONFIRM_UNGROUP_TEMPLATES: "您确定要取消这些模板的分组吗？",

    success: "成功",
    error: "错误",
    warning: "警告",

    // LAYOUT
    footer1: "legale.io 是一家 Despapeliza Llc 公司。 7345 W Sand Lake RD STE 210, OFC 6837, 佛罗里达州奥兰多",
    footer2: "保留所有权利 legale",
    signOut: "登出",

    // LOGIN
    login: "登录",
    email: "邮件",
    password: "密码",
    fieldRequired: "此字段是必需的",
    fieldInvalid: "无效值",
    fileLengthError: "文件名应少于 {{limit}} 个字符",
    submit: "提交",

    // HOME
    searchDocumentByNationalIDNumber: "按国民身份证号码搜索您的文件",
    sign: "签名",
    noSearchResult: "未找到包含您信息的文档",
    searchTip:
        "要找到等待您签名的文档，需要您的身份号码。 这个唯一的标识符确保我们将 yu 与正确的文档准确匹配。 请准备好您的身份证号码，并在出现提示时输入。",
    searchTipNoResult: "请找到您自己的合同文件并用您的身份证号码签署",
    fileName: "文档名字",
    subject: "主题",
    description: "描述",
    backToSearch: "返回搜索",

    // SIGN
    pdfPaginationDescription: "{{totalPage}} 的第 {{pageNumber}} 页",
    signatureImage: "签名图片",
    cancel: "取消",
    back: "上一步",
    next: "下一步",
    sendCodeToMyEmail: "将代码发送到我的电子邮件",
    sendCodeToMyPhone: "将代码发送到我的手机",
    verifyDescription:
        "为了确保您的信息安全，我们需要验证您的身份。 您可以选择通过电话或电子邮件接收验证码。 请选择您喜欢的方法。",
    selfieDescription: "作为我们确保最高级别安全和身份保护承诺的一部分，我们需要最后一项证据来确认您的身份。",
    signatureImageDrawingTip: "不要在二维码上签名",
    signatureImageDescription: "请在盒子上画上您的签名图片",
    clearSignature: "清除签名",
    note: "笔记",
    sendCode: "发送代码",
    verifyCodeDescription: "我们想确保确实是您。 为了进一步验证您的身份，请输入发送到 {{destination}} 的验证码。",
    confirm: "确认",
    resendCode: "重新发送验证码",
    code: "代码",
    phone: "手机",
};
