import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useWindowSize } from "../../services/hook/useWindowsSize";

const propTypes = {
    qrcode: PropTypes.string,
    topText: PropTypes.string,
    bottomText: PropTypes.string,
    setReadyToSign: PropTypes.func,
    setQrcodeLoaded: PropTypes.func,
};

const SignatureImageBoard = forwardRef(({ qrcode, topText, bottomText, setReadyToSign, setQrcodeLoaded }, ref) => {
    const wrapperRef = useRef(null);
    const canvasRef = useRef(null);
    const contextRef = useRef(null);

    const [winWidth] = useWindowSize();

    const [isDrawing, setIsDrawing] = useState(false);
    const [drawn, setDrawn] = useState(false);
    const [width, setWidth] = useState(0);

    useImperativeHandle(ref, () => ({
        clearCanvas() {
            clearCanvasHandler();
        },
        getSignatureImageBase64() {
            return drawn ? canvasRef.current.toDataURL() : "";
        },
    }));

    useEffect(() => {
        if (wrapperRef.current) {
            setWidth(wrapperRef.current.offsetWidth);
        }
    }, [winWidth]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        context.scale(1, 1);
        context.lineCap = "round";
        context.strokeStyle = "rgb(29, 56, 163)";
        context.lineWidth = 2;
        contextRef.current = context;
        clearCanvasHandler();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const canvas = canvasRef.current;
        canvas.width = width - 2;
        canvas.height = Math.ceil(width / 3);
        canvas.style.width = `${width - 2}px`;
        canvas.style.height = `${Math.ceil(width / 3)}px`;
        clearCanvasHandler();
    }, [width]); // eslint-disable-line react-hooks/exhaustive-deps

    const startDrawing = ({ nativeEvent }) => {
        const { offsetX, offsetY } = nativeEvent;
        contextRef.current.beginPath();
        contextRef.current.moveTo(offsetX, offsetY);
        setIsDrawing(true);
        setDrawn(true);
        if (setReadyToSign) setReadyToSign(true);
    };

    const finishDrawing = () => {
        contextRef.current.closePath();
        setIsDrawing(false);
    };

    const draw = ({ nativeEvent }) => {
        if (!isDrawing) return;
        const { offsetX, offsetY } = nativeEvent;
        contextRef.current.lineTo(offsetX, offsetY);
        contextRef.current.stroke();
    };

    const startTouching = (e) => {
        const touchEvent = e.touches[0];
        if (touchEvent && canvasRef.current) {
            let rect = canvasRef.current.getBoundingClientRect();
            const offsetX = touchEvent.clientX - rect.x;
            const offsetY = touchEvent.clientY - rect.y;
            contextRef.current.beginPath();
            contextRef.current.moveTo(offsetX, offsetY);
            setIsDrawing(true);
            setDrawn(true);
            if (setReadyToSign) setReadyToSign(true);
        }
    };

    const finishTouching = () => {
        contextRef.current.closePath();
        setIsDrawing(false);
    };

    const touch = (e) => {
        if (!isDrawing) return;
        const touchEvent = e.touches[0];
        if (touchEvent && canvasRef.current) {
            let rect = canvasRef.current.getBoundingClientRect();
            const offsetX = touchEvent.clientX - rect.x;
            const offsetY = touchEvent.clientY - rect.y;
            contextRef.current.lineTo(offsetX, offsetY);
            contextRef.current.stroke();
        }
    };

    const clearCanvasHandler = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        context.fillStyle = "#FFFFFF";
        context.fillRect(0, 0, canvas.width, canvas.height);
        setDrawn(false);
        if (setReadyToSign) setReadyToSign(false);

        context.beginPath();
        context.lineWidth = "1";
        context.strokeStyle = "rgb(129,129,129)";
        context.rect(1, 1, canvas.width - 2, canvas.height - 2);
        context.stroke();

        if (qrcode) {
            let img = new Image();
            img.crossOrigin = "anonymous";
            img.onload = function () {
                const qrCodeWidth = Math.round(canvas.height - (canvas.width > 590 ? 80 : 45));
                console.log(qrCodeWidth, width);
                context.drawImage(
                    img,
                    canvas.width - qrCodeWidth - 10,
                    canvas.height / 2 - Math.round(qrCodeWidth / 2),
                    qrCodeWidth,
                    qrCodeWidth
                );
                if (setQrcodeLoaded) setQrcodeLoaded(true);
            };
            img.src = qrcode;
        }
        if (topText) {
            context.font = width === 600 ? "18px sans-serif" : "13px sans-serif";
            context.fillStyle = "#323232";
            context.textAlign = "center";
            context.fillText(`${topText}`, canvas.width / 2, width === 600 ? 25 : 17);
        }
        if (bottomText) {
            context.font = width === 600 ? "18px sans-serif" : "13px sans-serif";
            context.fillStyle = "#323232";
            context.textAlign = "center";
            context.fillText(`${bottomText}`, canvas.width / 2, width === 600 ? canvas.height - 12 : canvas.height - 8);
        }
        context.lineWidth = "1";
        context.strokeStyle = "rgb(29, 56, 163)";
    };

    return (
        <Wrapper ref={wrapperRef}>
            <canvas
                id="signature_image"
                onMouseDown={startDrawing}
                onMouseUp={finishDrawing}
                onMouseMove={draw}
                onTouchStart={startTouching}
                onTouchEnd={finishTouching}
                onTouchMove={touch}
                ref={canvasRef}
            />
        </Wrapper>
    );
});

const Wrapper = styled.div`
    cursor: crosshair;

    canvas {
        touch-action: none;
    }
`;

SignatureImageBoard.propTypes = propTypes;

export default SignatureImageBoard;
