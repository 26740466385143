import * as actionTypes from "../actionTypes";
import { logout } from "./auth";

import i18n from "../../../translations/i18n";

export const updateLoading = (loading) => {
    return {
        type: actionTypes.UPDATE_LOADING,
        loading,
    };
};

export const updateMessage = (type, content) => {
    return {
        type: actionTypes.UPDATE_MESSAGE,
        message: {
            type,
            content,
        },
    };
};

export const updateLanguage = (language) => {
    return {
        type: actionTypes.UPDATE_LANGUAGE,
        language,
    };
};

export const errorManagementHandler = (e, callback = null) => {
    console.log(e);
    return (dispatch) => {
        if (e.message === "Network Error") {
            dispatch(updateMessage("warning", i18n.t("NETWORK_ERROR")));
        } else {
            if (!!e.response) {
                if (e.response.status === 401) {
                    dispatch(logout());
                    dispatch(updateMessage("error", i18n.t("UNAUTHORIZED")));
                } else if (e.response.status === 403) {
                    dispatch(updateMessage("error", i18n.t("ERROR_NO_PERMISSION")));
                } else if (e.response.status === 404) {
                    dispatch(updateMessage("error", i18n.t("NOT_FOUND")));
                } else if (e.response.status === 459) {
                    dispatch(updateMessage("error", i18n.t("ERROR_CONTACT_DUPLICATED")));
                } else if (e.response.status === 460) {
                    dispatch(updateMessage("error", i18n.t("ERROR_LINK_INVALID")));
                } else if (e.response.status === 461) {
                    dispatch(updateMessage("error", i18n.t("ERROR_LINK_EXPIRED")));
                } else if (e.response.status === 462) {
                    dispatch(updateMessage("error", i18n.t("ERROR_NOT_OWNER")));
                } else if (e.response.status === 463) {
                    dispatch(updateMessage("error", i18n.t("ERROR_INVALID_REQUEST_DATA")));
                } else if (e.response.status === 464) {
                    dispatch(updateMessage("error", i18n.t("ERROR_EMAIL_NOT_EXIST")));
                } else if (e.response.status === 465) {
                    dispatch(updateMessage("error", i18n.t("ERROR_WRONG_PASSWORD")));
                } else if (e.response.status === 466) {
                    dispatch(updateMessage("error", i18n.t("ACCOUNT_NOT_ACTIVATED")));
                } else if (e.response.status === 467) {
                    dispatch(updateMessage("error", i18n.t("ERROR_INVALID_CREDENTIAL")));
                } else if (e.response.status === 468) {
                    dispatch(updateMessage("error", i18n.t("ERROR_USER_NOT_FOUND")));
                } else if (e.response.status === 469) {
                    dispatch(updateMessage("error", i18n.t("ERROR_CODE_EXPIRED")));
                } else if (e.response.status === 470) {
                    dispatch(updateMessage("error", i18n.t("ERROR_INVALID_VERIFICATION_CODE")));
                } else if (e.response.status === 471) {
                    dispatch(updateMessage("error", i18n.t("ERROR_NO_STORAGE")));
                } else if (e.response.status === 472) {
                    dispatch(updateMessage("error", i18n.t("ERROR_NO_DOCUMENT_NUMBER")));
                } else if (e.response.status === 473) {
                    dispatch(updateMessage("error", i18n.t("ERROR_SIGN_FLOW_LEGALE")));
                } else if (e.response.status === 474) {
                    dispatch(updateMessage("error", i18n.t("ERROR_SIGN_FLOW_APPROVE")));
                } else if (e.response.status === 475) {
                    dispatch(updateMessage("error", i18n.t("ERROR_SIGN_FLOW_TABLET")));
                } else if (e.response.status === 476) {
                    dispatch(updateMessage("error", i18n.t("ERROR_SIGNATURE_NUMBER_SMS")));
                } else if (e.response.status === 477) {
                    dispatch(updateMessage("error", i18n.t("ERROR_SIGNATURE_NUMBER_NO_SMS")));
                } else if (e.response.status === 478) {
                    dispatch(updateMessage("error", i18n.t("ERROR_SIGNATURE_NUMBER_LOCAL_CERTIFICATE")));
                } else if (e.response.status === 479) {
                    dispatch(updateMessage("error", i18n.t("ERROR_SIGNATURE_NUMBER_CERTIFICATION")));
                } else if (e.response.status === 480) {
                    dispatch(updateMessage("error", i18n.t("ERROR_SIGNATURE_NUMBER_STAMP")));
                } else if (e.response.status === 481) {
                    dispatch(updateMessage("error", i18n.t("ERROR_SIGNATURE_NUMBER_TABLET_SIGN")));
                } else if (e.response.status === 482) {
                    dispatch(updateMessage("error", i18n.t("ERROR_SIGNATURE_NUMBER_VIDEO_VALIDATION")));
                } else if (e.response.status === 483) {
                    dispatch(updateMessage("error", i18n.t("ERROR_SIGNATURE_NUMBER_SELFIE_VALIDATION")));
                } else if (e.response.status === 484) {
                    dispatch(updateMessage("error", i18n.t("ERROR_SIGNATURE_NUMBER_ID_VALIDATION")));
                } else if (e.response.status === 485) {
                    dispatch(updateMessage("error", i18n.t("ERROR_LEGALE_NOT_FOUND")));
                } else if (e.response.status === 486) {
                    dispatch(updateMessage("error", i18n.t("ERROR_DEVICE_NOT_FOUND")));
                } else if (e.response.status === 487) {
                    dispatch(updateMessage("error", i18n.t("ERROR_SIGNATURE_IMAGE_POSITION")));
                } else if (e.response.status === 488) {
                    dispatch(updateMessage("error", i18n.t("ERROR_NO_CERTIFICATION")));
                } else if (e.response.status === 489) {
                    dispatch(updateMessage("error", i18n.t("ERROR_ALREADY_SIGNED_REJECTED")));
                } else if (e.response.status === 490) {
                    dispatch(updateMessage("error", i18n.t("ERROR_NOT_DOCUMENT_OWNER")));
                } else if (e.response.status === 560) {
                    dispatch(updateMessage("error", i18n.t("ERROR_INVALID_DISCOUNT_CODE")));
                } else if (e.response.status === 561) {
                    dispatch(updateMessage("error", i18n.t("ERROR_COUPON_ALREADY_USED")));
                } else if (e.response.status === 562) {
                    dispatch(updateMessage("error", i18n.t("ERROR_NO_STRIPE_CUSTOMER")));
                } else if (e.response.status === 563) {
                    dispatch(updateMessage("error", i18n.t("ERROR_DOCUMENT_NOT_FOUND")));
                } else if (e.response.status === 564) {
                    dispatch(updateMessage("error", i18n.t("ERROR_SMART_FLUX_NOT_FOUND")));
                } else if (e.response.status === 565) {
                    dispatch(updateMessage("error", i18n.t("ERROR_TEMPLATE_NOT_FOUND")));
                } else if (e.response.status === 571) {
                    dispatch(updateMessage("error", i18n.t("ERROR_NOT_ALLOWED_TO_USE_CERTIFICATE")));
                } else if (e.response.status === 572) {
                    dispatch(updateMessage("error", i18n.t("ERROR_NOT_ALLOWED_SECONDARY_USER")));
                } else if (e.response.status === 573) {
                    dispatch(updateMessage("error", i18n.t("ERROR_NOT_ALLOWED_TO_USE_SMART_FLUX")));
                } else if (e.response.status === 574) {
                    dispatch(updateMessage("error", i18n.t("ERROR_NOT_ALLOWED_TO_USE_TEMPLATE")));
                } else if (e.response.status === 575) {
                    dispatch(updateMessage("error", i18n.t("ERROR_NOT_ALLOWED_TO_CREATE_SIGN_FLUX")));
                } else if (e.response.status === 576) {
                    dispatch(updateMessage("error", i18n.t("ERROR_NOT_ALLOWED_LOCAL_CERTIFICATE")));
                } else if (e.response.status === 580) {
                    dispatch(updateMessage("error", i18n.t("ERROR_NOT_READY_SIGN")));
                } else if (e.response.status === 581) {
                    dispatch(updateMessage("error", i18n.t("ERROR_DOCUMENT_DELETED_OR_SIGN_CANCELLED")));
                } else if (e.response.status === 582) {
                    dispatch(updateMessage("error", i18n.t("ERROR_INVALID_OTP")));
                } else if (e.response.status === 583) {
                    dispatch(updateMessage("error", i18n.t("ERROR_NO_SIGNATURE_IMAGE")));
                } else if (e.response.status === 584) {
                    dispatch(updateMessage("error", i18n.t("ERROR_DOCUMENT_NOT_SIGNED")));
                } else if (e.response.status === 585) {
                    dispatch(updateMessage("error", i18n.t("ERROR_CONTACT_NOT_FOUND")));
                } else if (e.response.status === 586) {
                    dispatch(updateMessage("error", i18n.t("ERROR_CONTACT_DUPLICATED")));
                } else if (e.response.status === 587) {
                    dispatch(updateMessage("error", i18n.t("ERROR_NO_CHAT_ADMIN")));
                } else if (e.response.status === 588) {
                    dispatch(updateMessage("warning", i18n.t("ERROR_DOCUMENT_SIGN_EXPIRED")));
                } else if (e.response.status === 589) {
                    dispatch(updateMessage("error", i18n.t("ERROR_FOLDER_CREATE_NOT_ALLOWED")));
                } else if (e.response.status === 590) {
                    dispatch(updateMessage("error", i18n.t("ERROR_FOLDER_RENAME_NOT_ALLOWED")));
                } else if (e.response.status === 591) {
                    dispatch(updateMessage("error", i18n.t("ERROR_FOLDER_DELETE_NOT_ALLOWED")));
                } else if (e.response.status === 592) {
                    dispatch(updateMessage("error", i18n.t("ERROR_DOCUMENT_RENAME_NOT_ALLOWED")));
                } else if (e.response.status === 593) {
                    dispatch(updateMessage("error", i18n.t("ERROR_DOCUMENT_DELETE_NOT_ALLOWED")));
                } else if (e.response.status === 594) {
                    dispatch(updateMessage("error", i18n.t("ERROR_NOT_AVAILABLE_TO_UPLOAD_TO_FOLDER")));
                } else if (e.response.status === 595) {
                    dispatch(updateMessage("error", i18n.t("ERROR_INVALID_REMOTE_PATH")));
                } else if (e.response.status === 596) {
                    dispatch(updateMessage("error", i18n.t("ERROR_CSV_IS_NOT_MATCHED")));
                } else if (e.response.status === 597) {
                    dispatch(updateMessage("error", i18n.t("ERROR_DUPLICATED_FOLDER_NAME")));
                } else {
                    if (!!e.response.data.code) {
                        dispatch(updateMessage("error", i18n.t(e.response.data.code)));
                    } else {
                        if (e.response.status === 405 || e.response.status === 400) {
                            dispatch(updateMessage("error", i18n.t("BAD_REQUEST")));
                        } else {
                            dispatch(updateMessage("error", i18n.t("SERVICE_ERROR")));
                        }
                    }
                }
            } else {
                dispatch(updateMessage("error", i18n.t("SERVICE_ERROR")));
            }
        }
        if (!!callback) callback();
        dispatch(updateLoading(false));
    };
};
