import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const propTypes = {
    children: PropTypes.any,
    withFooter: PropTypes.bool,
};

const FixedActionBar = ({ children, withFooter }) => (
    <Wrapper className={withFooter ? "with-footer" : ""}>{children}</Wrapper>
);

const Wrapper = styled.div`
    width: 100%;

    position: fixed;
    bottom: 0;
    right: 0;

    padding: 10px 0;

    background-color: ${(props) => props.theme.inputIcon};

    z-index: 102;

    &.with-footer {
        bottom: 53px;
    }
`;

FixedActionBar.propTypes = propTypes;

export default FixedActionBar;
