import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Document, Page, pdfjs } from "react-pdf";

import { Loader } from "../..";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "./styles.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const propTypes = {
    url: PropTypes.string.isRequired,
    width: PropTypes.number,
};

const PdfThumbnail = ({ url, width = 200 }) => {
    const documentRef = useRef();

    return (
        <div className="legale-pdf-thumbnail-viewer-wrapper">
            <div style={{ position: "relative" }}>
                {url !== null ? (
                    <React.Fragment>
                        <Document
                            className="legale-pdf-viewer scroll-y-without-bar scroll-x-without-bar"
                            renderMode="canvas"
                            loading={
                                <div className="legale-pdf-loading">
                                    <Loader />
                                </div>
                            }
                            file={url}
                            inputRef={documentRef}
                            onLoadSuccess={() => {}}
                            style={{ width: `${width}px` }}
                        >
                            <Page
                                className="legale-pdf-viewer-page"
                                width={width}
                                pageNumber={1}
                                renderTextLayer={false}
                                renderAnnotationLayer={true}
                                renderInteractiveForms={true}
                                scale={1}
                            />
                        </Document>
                    </React.Fragment>
                ) : null}
            </div>
        </div>
    );
};

PdfThumbnail.propTypes = propTypes;

export default PdfThumbnail;
