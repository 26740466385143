import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";

const propTypes = {
    phone: PropTypes.string,
    setCountry: PropTypes.func,
    setPhone: PropTypes.func,
};

const LegalePhoneInput = ({ phone, setCountry, setPhone }) => {
    return (
        <Wrapper>
            <PhoneInput
                preferredCountries={["cl", "us", "br", "mx", "es"]}
                masks={{
                    cl: ". .... ....",
                    us: "(...) ... ....",
                    br: ".. ..... ....",
                    mx: ".. .. .... ....",
                    es: ".. ... .. ..",
                    cn: "... .... ....",
                    ar: "... ....-....",
                }}
                countryCodeEditable={false}
                value={phone}
                onChange={(value, countryData) => {
                    setPhone(value);
                    if (setCountry) setCountry(countryData.countryCode);
                }}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    input {
        width: 100% !important;
        height: 40px !important;
        background: ${(props) => props.theme.white} !important;
        color: ${(props) => props.theme.inputColor} !important;
        border: 1px solid ${(props) => props.theme.inputBorder} !important;
        box-sizing: border-box !important;
        border-radius: ${(props) => (props.outline ? 0 : 8)}px !important;
        border-top: ${(props) => (props.outline ? "none" : "")} !important;
        border-left: ${(props) => (props.outline ? "none" : "")} !important;
        border-right: ${(props) => (props.outline ? "none" : "")} !important;
        padding-left: ${(props) => (props.outline ? 0 : "")} !important;
        :focus {
            box-shadow: none;
        }
    }
    .flag-dropdown {
        border: 1px solid ${(props) => props.theme.inputBorder} !important;
        background: ${(props) => props.theme.white} !important;
        border-radius: 8px 0 0 8px !important;
        .selected-flag {
            border-radius: 8px 0 0 8px !important;
            background-color: ${(props) => props.theme.white} !important;
        }
        .country-list {
            background-color: ${(props) => props.theme.white};
            color: ${(props) => props.theme.inputColor} !important;
            .country {
                &:hover {
                    background-color: ${(props) => props.theme.menuHoverBg};
                }
                &.highlight {
                    background-color: ${(props) => props.theme.menuActiveBg};
                }
            }
        }
    }
`;

LegalePhoneInput.propTypes = propTypes;

export default LegalePhoneInput;
