import styled from "styled-components";

import { rgba } from "polished";

export const Table1 = styled.table`
    width: 100%;

    box-shadow: 0 3px 5px ${(props) => rgba(props.theme.black, 0.03)};

    border-radius: 15px;
    border-collapse: collapse;

    thead {
        tr {
            &:not(:first-child) {
                th {
                    border-radius: 0 !important;
                }
            }

            th {
                background: ${(props) => rgba(props.theme.orange, 0.25)};
                color: ${(props) => rgba(props.theme.black, 0.8)};
                vertical-align: middle;
                border: none !important;

                padding: 12px;

                text-align: left;

                &:first-child {
                    border-top-left-radius: 15px;
                }
                &:last-child {
                    border-top-right-radius: 15px;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                background: ${(props) => props.theme.white};
                color: ${(props) => rgba(props.theme.black, 0.7)};
                vertical-align: middle;
                border: none !important;

                padding: 12px;

                text-align: left;

                .actions {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    white-space: nowrap;
                }
            }

            &.action-tr {
                td {
                    padding-top: 0;
                }
            }

            &:last-child {
                td {
                    &:first-child {
                        border-bottom-left-radius: 15px;
                    }
                    &:last-child {
                        border-bottom-right-radius: 15px;
                    }
                }
            }
        }
    }

    &:not(.with-action-tr) {
        tbody {
            tr {
                &:nth-child(even) {
                    td {
                        background: ${(props) => props.theme.gray100};
                    }
                }
            }
        }
    }

    &.with-action-tr {
        tbody {
            tr {
                &.odd {
                    td {
                        background: ${(props) => props.theme.gray100};
                    }
                }
            }
        }
    }
`;
