import React from "react";
import PropTypes from "prop-types";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Dropdown } from "antd";

import { updateLanguage } from "../../../services/redux/actions/theme";

const propTypes = {
    placement: PropTypes.string,
    disabled: PropTypes.bool,
    noBg: PropTypes.bool,
};

const languages = [
    { code: "en", text: "English" },
    { code: "es", text: "Español" },
    { code: "pt", text: "Português" },
    { code: "zh", text: "简体中文" },
    { code: "fr", text: "French" },
    { code: "it", text: "Italian" },
    { code: "ca", text: "Catalan" },
];

const flagIcon = (code) => {
    switch (code) {
        case "en":
            return <span className="fi fi-us" />;
        case "es":
            return <span className="fi fi-es" />;
        case "pt":
            return <span className="fi fi-br" />;
        case "de":
            return <span className="fi fi-de" />;
        case "fr":
            return <span className="fi fi-fr" />;
        case "it":
            return <span className="fi fi-it" />;
        case "zh":
            return <span className="fi fi-cn" />;
        case "ca":
            return <span className="fi fi-es-ct" />;
        default:
            return <span className="fi fi-us" />;
    }
};

const LanguageDropdown = ({ placement, disabled, noBg = false }) => {
    const dispatch = useDispatch();

    const { language, theme } = useSelector((state) => state.theme);

    const languageChangeHandler = async ({ key: language }) => {
        await i18n.changeLanguage(language);
        dispatch(updateLanguage(language));
    };

    const languageMenu = languages.map((language) => ({
        key: language.code,
        label: (
            <div>
                <span className="mr-3">{flagIcon(language.code)}</span>
                <span>{language.text}</span>
            </div>
        ),
    }));

    return (
        <Dropdown
            menu={{
                items: languageMenu,
                onClick: languageChangeHandler,
            }}
            overlayClassName={`legale-dropdown-${theme}`}
            placement={placement || "bottomRight"}
            disabled={disabled}
            trigger={["click"]}
        >
            <div className="account-dropdown-toggle">
                <Button
                    className={noBg ? "no-border bg-transparent" : "no-border"}
                    shape="circle"
                    icon={flagIcon(language)}
                    size="middle"
                />
            </div>
        </Dropdown>
    );
};

LanguageDropdown.propTypes = propTypes;

export default LanguageDropdown;
