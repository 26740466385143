export const TRANSLATIONS_ES = {
    // RESPONSE MESSAGE
    UNAUTHORIZED: "No autorizado",
    TOKEN_EXPIRED: "Sesión expirada",
    ACCOUNT_NOT_ACTIVATED: "Active su cuenta en su correo electrónico",
    NOT_FOUND: "Servicio no encontrado",
    BAD_REQUEST: "Solicitud incorrecta",
    SERVICE_ERROR: "Error de servicio",
    NETWORK_ERROR:
        "Disculpa, estamos realizando una mejora en el sistema, hemos incorporando nuevas funcionalidades y unas actualizaciones de seguridad, danos unos minutos más para terminar. Pronto podrás disfrutar de ellas.",
    WENT_WRONG: "Algo salió mal, intenta de nuevo",
    BLANK_IMAGE_UPLOAD_ERROR: "No se puede cargar la imagen en blanco",
    ERROR_DUPLICATED_EMAIL: "Ya existe un usuario con el mismo correo electrónico",
    ERROR_DOCUMENT_NOT_SIGNED: "Documento no firmado",
    ERROR_DOCUMENT_NOT_FOUND: "Documento no encontrado",
    ERROR_DOCUMENT_DELETED: "El remitente eliminó el documento",
    ERROR_API: "Error de API",
    ERROR_DOCUMENT_SIZE_LARGE: "El tamaño del documento es demasiado grande",
    ERROR_FILE_SIZE_LARGE: "El tamaño del archivo es demasiado grande",
    ERROR_NOT_READY_SIGN: "Documento no listo para firmar",
    ERROR_ALREADY_IN_PROCESS: "El documento ya está en proceso",
    ERROR_GETTING_SIGNED_DOCUMENT: "Error al obtener el documento firmado",
    ERROR_INVALID_OTP: "OTP no válida",
    ERROR_DOCUMENT_ALREADY_FAILED: "El estado del documento ya falló",
    ERROR_NOT_READY_OTP: "No está listo para enviar OTP, primero cree una solicitud",
    ERROR_NO_PERMISSION: "No tienes permiso",
    ERROR_EMAIL_NOT_EXIST: "El correo electrónico no existe",
    ERROR_INVALID_CREDENTIAL: "El correo electrónico o la contraseña no son válidos",
    ERROR_WRONG_PASSWORD: "Contraseña incorrecta",
    ERROR_IP_DIFF: "Problema de seguridad detectado",
    ERROR_USER_NOT_FOUND: "Usuario no encontrado",
    ERROR_CODE_EXPIRED: "El código de verificación caducó",
    ERROR_INVALID_VERIFICATION_CODE: "Código de verificación invalido",
    ERROR_ALREADY_SIGNED_REJECTED: "El documento ya estaba firmado o rechazado",
    ERROR_NO_CERTIFICATION: "Sin certificado",
    ERROR_INVALID_GUID: "GUID no válido",
    ERROR_SUBSCRIPTION_EXPIRED: "Su suscripción ha caducado",
    ERROR_NOT_ALLOWED_TO_USE_OPTION: "No puede usar esta opción con su plan",
    ERROR_TEMPLATE_NOT_FOUND: "La plantilla no existe",
    ERROR_NO_STRIPE_CUSTOMER: "No estas suscrito a Stripe",
    ERROR_NO_CHAT_ADMIN: "No eres un administrador. No tiene permiso para recuperar la lista de administradores",
    ERROR_NO_STORAGE:
        "Se agotó el almacenamiento o el número de documento, actualice su plan o comuníquese con soporte",
    ERROR_INVALID_REQUEST_DATA: "Los datos de la solicitud no son válidos, verifique su solicitud e intente nuevamente",
    ERROR_NO_DOCUMENT_NUMBER:
        "Esta función está deshabilitada porque se quedó sin firmas, compre más documentos o actualice su plan",
    ERROR_NOT_ALLOWED_TO_CREATE_SIGN_FLUX:
        "No tiene permiso para crear flujo de firmas, comuníquese con su administrador",
    INVALID_DOCUMENT_STATUS: "El documento no está disponible para comenzar a firmar",
    ERROR_NOT_ALLOWED_TO_REGISTER_DEVICE:
        "No puede usar la función del dispositivo, actualice su plan o comuníquese con soporte",
    ERROR_TABLET_NOT_FOUND: "La tableta con este ID de dispositivo no existe o usted no es el propietario",
    ERROR_NO_SIGNATURE_IMAGE: "Sin imagen de firma",
    ERROR_DOCUMENT_DELETED_OR_SIGN_CANCELLED: "Se eliminó el documento o se canceló el flujo de firmas",
    ERROR_NOT_ALLOWED_USE_CERTIFICATE:
        "No puede usar la funcionalidad de certificado en la nube en su plan, actualícelo",
    ERROR_NOT_ADMIN: "No eres administrador de la cuenta",
    ERROR_CONTACT_NOT_FOUND: "Contacto no encontrado",
    ERROR_INVALID_DISCOUNT_CODE: "Código de descuento no válido",
    ERROR_NOT_DOCUMENT_OWNER: "No eres dueño de este documento",
    ERROR_NOT_ALLOWED_TO_USE_TEMPLATE:
        "No puede usar la función de plantilla inteligente, actualice su plan o comuníquese con soporte",
    ERROR_NOT_ALLOWED_TO_USE_SMART_FLUX:
        "No puede usar la funcionalidad de flujo inteligente, actualice el plan o comuníquese con soporte",
    ERROR_NOT_DOCUMENT_SENDER: "No eres el remitente de este documento, no tienes permiso",
    ERROR_NOT_ALLOWED_LOCAL_CERTIFICATE:
        "No puede usar la función de certificado local, actualice su plan o comuníquese con soporte",
    ERROR_SIGN_FLOW: "Flujo de señal no válido",
    ERROR_SIGN_METHOD: "Método de signo no válido",
    ERROR_SIGN_FLOW_LEGALE:
        "No puede enviar documentos a otro usuario legal, actualice su plan o comuníquese con soporte",
    ERROR_SIGN_FLOW_APPROVE: "No puede enviar documentos para aprobar, actualice su plan o comuníquese con soporte",
    ERROR_SIGN_FLOW_TABLET:
        "No puede usar el flujo de dispositivos móviles, actualice su plan o comuníquese con soporte",
    ERROR_SIGNATURE_NUMBER_SMS:
        "No tiene suficiente número de firma para la firma remota de SMS o no puede usar este método de firma",
    ERROR_SIGNATURE_NUMBER_NO_SMS:
        "No tiene suficiente número de firma para la firma del certificado local o no puede usar este método de firma",
    ERROR_SIGNATURE_NUMBER_LOCAL_CERTIFICATE:
        "No tiene suficiente número de firma para la firma del certificado local o no puede usar este método de firma",
    ERROR_SIGNATURE_NUMBER_CERTIFICATION:
        "No tiene suficiente número de firma para la firma del certificado en la nube o no puede usar este método de firma",
    ERROR_SIGNATURE_NUMBER_STAMP:
        "No tiene suficiente número de firma para la firma del sello o no puede usar este método de firma",
    ERROR_SIGNATURE_NUMBER_TABLET_SIGN:
        "No tiene suficiente número de firma para la firma del dispositivo móvil o no puede usar este método de firma",
    ERROR_SIGNATURE_NUMBER_VIDEO_VALIDATION:
        "No tiene un número suficiente para la verificación de video o no puede usar este método de verificación",
    ERROR_SIGNATURE_NUMBER_SELFIE_VALIDATION:
        "No tiene un número suficiente para la verificación de Selfie o no puede usar este método de verificación",
    ERROR_SIGNATURE_NUMBER_ID_VALIDATION:
        "No tiene un número suficiente para la verificación de identidad o no puede usar este método de verificación",
    ERROR_LEGALE_NOT_FOUND: "No existe el usuario Legale, a quien vas a enviar documento para firmar",
    ERROR_DEVICE_NOT_FOUND: "Dispositivo móvil, que vas a enviar documento para firmar no existe",
    ERROR_SIGNATURE_IMAGE_POSITION: "Ubicación de imagen de firma no válida",
    ERROR_NOT_ALLOWED_SECONDARY_USER:
        "No es administrador de la cuenta o no tiene permiso para usar la función de usuario secundario",
    ERROR_COUPON_ALREADY_USED: "Ya usaste este código de descuento",
    ERROR_LINK_INVALID: "Este enlace no es válido",
    ERROR_LINK_EXPIRED: "Este enlace está caducado",
    ERROR_NOT_OWNER: "No eres dueño",
    ERROR_CONTACT_DUPLICATED: "Ya existe contacto con el mismo correo electrónico",
    ERROR_DOCUMENT_SIGN_EXPIRED: "El flujo de firma del documento ha caducado, no puede continuar con este flujo",
    ERROR_SMART_FORM_LINK_EXPIRED: "Este enlace ha caducado y ya no está disponible",
    ERROR_FOLDER_CREATE_NOT_ALLOWED: "No tienes permiso para crear una carpeta.",
    ERROR_FOLDER_RENAME_NOT_ALLOWED: "No tienes permiso para cambiar el nombre de la carpeta.",
    ERROR_FOLDER_DELETE_NOT_ALLOWED: "No tienes permiso para eliminar la carpeta.",
    ERROR_DOCUMENT_RENAME_NOT_ALLOWED: "No se le permite cambiar el nombre del documento",
    ERROR_DOCUMENT_DELETE_NOT_ALLOWED: "No se le permite eliminar el documento",
    ERROR_NOT_AVAILABLE_TO_UPLOAD_TO_FOLDER: "No disponible para subir documentos a la carpeta actual, está llena",
    ERROR_INVALID_REMOTE_PATH: "La ruta remota no es válida",
    ERROR_CSV_IS_NOT_MATCHED: "El formato CSV no coincide con la actualización",
    ERROR_DUPLICATED_FOLDER_NAME: "Nombre de carpeta duplicado",
    ERROR_DOCUMENT_BROKEN: "El documento está roto o dañado, no se puede iniciar el flujo de firmas",

    SUCCESS_PURCHASE: "La compra se realizó con éxito",
    SUCCESS_SENT_OTP: "Se envió OTP a su teléfono, verifique su SMS",
    SUCCESS_OTP: "OTP enviado con éxito",
    SUCCESS_SUBSCRIBED: "Suscrito exitosamente",
    SUCCESS_RESENT: "Reenviado con éxito",
    SUCCESS_SIGNER_REMOVED: "Firmante eliminado con éxito",
    SUCCESS_DOCUMENT_SENT: "Documento enviado exitosamente",
    SUCCESS_DOCUMENT_SENT_TO_EMAIL: "El documento a firmar fue enviado al correo electrónico",
    SUCCESS_DOCUMENT_RESENT: "Documento reenviado con éxito",
    SUCCESS_SIGNED: "Firmado exitosamente",
    SUCCESS_DOCUMENT_SHARED: "Documento compartido con éxito",
    SUCCESS_UPDATE_PROFILE: "Actualizar perfil correctamente",
    SUCCESS_UPLOAD_USER_VIDEO: "Subir video con éxito",
    SUCCESS_UPLOAD_SIGNATURE_IMAGE: "Subir la imagen de la firma correctamente",
    SUCCESS_UPLOAD_CERTIFICATION: "Certificado subido correctamente",
    SUCCESS_UPDATED_CERTIFICATION: "Contraseña actualizada exitosamente",
    SUCCESS_REMOVED_CERTIFICATION: "Certificado eliminado con éxito",
    SUCCESS_UPDATED_CONFIGURATION: "Actualizar la configuración con éxito",
    SUCCESS_UPDATED_PASSWORD: "Actualizar la contraseña correctamente",
    SUCCESS_ADD_SIGNATURE: "Agregar número de firma correctamente",
    SUCCESS_CREATED_USER: "Usuario creado con éxito",
    SUCCESS_UPDATED_USER: "Usuario actualizado con éxito",
    SUCCESS_DELETED_USER: "Usuario eliminado exitosamente",
    SUCCESS_CREATED_CONTACT: "Contacto creado con éxito",
    SUCCESS_UPDATED_CONTACT: "Contacto actualizado con éxito",
    SUCCESS_DELETED_CONTACT: "Contacto eliminado con éxito",
    SUCCESS_DELETED_CONTACTS: "Contactos eliminados con éxito",
    SUCCESS_IMPORTED_CONTACT: "Contacto importado correctamente",
    SUCCESS_UPLOADED_DOCUMENT: "Documento subido correctamente",
    SUCCESS_UPLOADED_FILE: "Archivos subidos con éxito",
    SUCCESS_UPDATED_DOCUMENT: "Documento actualizado con éxito",
    SUCCESS_DELETED_DOCUMENT: "Documento eliminado correctamente",
    SUCCESS_REJECTED: "Rechazado exitosamente",
    SUCCESS_UPLOADED_ATTACHED_FILE: "Archivo adjunto subido correctamente",
    SUCCESS_DELETED_ATTACHED_FILE: "Archivo adjunto eliminado correctamente",
    SUCCESS_PAUSED_FLOW: "El flujo se detuvo correctamente",
    SUCCESS_SIGNED_DOCUMENT: "Documento firmado con éxito",
    SUCCESS_PLAY_FLOW: "El flujo volvió a reproducirse con éxito",
    SUCCESS_SIGN_PROCESS_STARTED: "Firmar hilo iniciado",
    SUCCESS_APPROVED_DOCUMENT: "Documento aprobado con éxito",
    SUCCESS_APPLIED: "Aplicado con éxito",
    SUCCESS_SIGN_BACKGROUND:
        "El proceso de firma está en segundo plano, recibirá el estado a través de su correo electrónico",
    SUCCESS_DELETED_TABLET: "Tableta eliminada con éxito",
    SUCCESS_CANCEL_SUBSCRIPTION: "Suscripción cancelada con éxito",
    SUCCESS_CREATE_CUSTOMIZE_PLAN: "Pronto te pondrás en contacto",
    SUCCESS_LINK_COPIED_TO_CLIPBOARD: "El enlace del formulario se copió en su portapapeles",
    SUCCESS_CSV_LINK_TEMPLATE: "CSV está vinculado a la plantilla con éxito",
    SUCCESS_CSV_WORKFLOW_UPDATE: "El flujo de trabajo CSV se actualizó correctamente",
    SUCCESS_CSV_ALARM_UPDATE: "La alarma CSV se actualizó correctamente",
    SUCCESS_CSV_WORKFLOW_LAUNCH: "El flujo de trabajo CSV se inició correctamente",
    SUCCESS_SAVED: "Guardado exitosamente",

    CONFIRM_REMOVE_CONTACT: "¿Estás seguro de eliminar este contacto?",
    CONFIRM_REMOVE_CONTACTS: "¿Estás seguro de eliminar estos contactos?",
    CONFIRM_SET_AS_EXTERNAL_CONTACTS: "¿Estás seguro de configurar estos contactos como usuario externo?",
    CONFIRM_APPROVE_DOCUMENT: "¿Estás seguro de aprobar este documento?",
    CONFIRM_REPLACE_VIDEO_VALIDATION: "Este documento ya tiene la validación de video, ¿está seguro de reemplazarlo?",
    CONFIRM_REPLACE_SELFIE_VALIDATION: "Este documento ya tiene la imagen del selfie, ¿está seguro de reemplazarlo?",
    CONFIRM_REPLACE_ID_VALIDATION: "Este documento ya tiene la imagen del documento, ¿está seguro de reemplazarlo?",
    CONFIRM_REMOVE_CERTIFICATION: "¿Estás seguro de eliminar esta certificación?",
    CONFIRM_REMOVE_SIGNER: "¿Estás seguro de eliminar este firmante?",
    CONFIRM_DELETE_DOCUMENT: "¿Está seguro de eliminar este documento?",
    CONFIRM_NOTIFICATION_MULTIPLE: "¿Estás seguro de enviar una notificación a estos firmantes?",
    CONFIRM_DELETE_DOCUMENT_MULTIPLE: "¿Estás seguro de eliminar los documentos seleccionados?",
    CONFIRM_PAUSE_DOCUMENT: "¿Está seguro de pausar el flujo de firmas de este documento?",
    CONFIRM_END_SIGN_FLOW: "¿Estás seguro de terminar este flujo de firmas?",
    CONFIRM_CONTINUE_FLOW: "¿Estás seguro de continuar con este flujo de firmas?",
    CONFIRM_DELETE_FILE: "¿Estás seguro de eliminar este archivo?",
    CONFIRM_REMOVE_USER: "¿Estás seguro de eliminar a este usuario?",
    CONFIRM_REMOVE_TABLET: "¿Estás seguro de eliminar esta tableta?",
    CONFIRM_REPLACE_USER_VIDEO: "¿Estás seguro de reemplazar el video?",
    CONFIRM_MAKE_AS_ADMIN: "¿Estás seguro de hacer este usuario como administrador?",
    CONFIRM_REMOVE_FLUX: "¿Estás seguro de eliminar este flujo?",
    CONFIRM_SEND_DOCUMENT_USE_FLUX: "¿Está seguro de enviar estos documentos utilizando este flujo?",
    CONFIRM_REMOVE_TEMPLATE: "¿Estás seguro de eliminar esta plantilla?",
    CONFIRM_DUPLICATE_TEMPLATE: "¿Estás seguro de duplicar esta plantilla?",
    CONFIRM_CANCEL_SUBSCRIPTION: "¿Estás seguro de cancelar la suscripción?",
    CONFIRM_MAKE_AS_CUSTOMIZED_PLAN: "¿Está seguro de asignar un plan a medida a este cliente?",
    CONFIRM_DELETE_FOLDER: "¿Estás seguro de eliminar esta carpeta?",
    CONFIRM_REMOVE_FORM: "¿Estás seguro de eliminar este formulario?",
    CONFIRM_DELETE_ALARM: "¿Estás seguro de eliminar esta alarma?",
    CONFIRM_DELETE: "¿Estás seguro de eliminar?",
    CONFIRM_LAUNCH_WORKFLOW: "¿Estás seguro de iniciar Workflow?",
    CONFIRM_SAVE_TEMPLATE: "Al guardar crearás una nueva versión, ¿estás seguro de hacerlo?",
    CONFIRM_LAUNCH_MULTIPLE_CSVS_WORKFLOW: "¿Estás seguro de iniciar el flujo de trabajo de estos csvs?",
    CONFIRM_UNGROUP_TEMPLATES: "¿Estás seguro de desagrupar estas plantillas?",

    success: "Éxito",
    error: "Error",
    warning: "Advertencia",

    // LAYOUT
    footer1: "legale.io es una empresa de Despapeliza Llc. 7345 W Sand Lake RD STE 210, OFC 6837, Orlando, FL, USA",
    footer2: "Todos los derechos reservados legale",
    signOut: "Salir",

    // LOGIN
    login: "Iniciar Sesión",
    email: "Email",
    password: "Contraseña",
    fieldRequired: "Este campo es obligatorio",
    fieldInvalid: "Valor no válido",
    fileLengthError: "El nombre del archivo debe tener menos de {{limit}} caracteres",
    submit: "Enviar",

    // HOME
    searchDocumentByNationalIDNumber: "Busca tu documento por número de ID",
    sign: "Firmar",
    noSearchResult: "No se encontró ningún documento con su información",
    searchTip:
        "Para localizar el documento que está esperando su firma, se requiere su número de identificación. Este identificador único garantiza que le relacionaremos con precisión con el documento correcto. Tenga a mano su número de identificación e ingréselo cuando se le solicite.",
    searchTipNoResult:
        "Encuentre su propio documento de contrato para firmar con su número de identificación nacional.",
    fileName: "Nombre del archivo",
    subject: "Sujeto",
    description: "Descripción",
    backToSearch: "Volver a buscar",

    // SIGN
    pdfPaginationDescription: "Página {{pageNumber}} de {{totalPage}}",
    signatureImage: "Imagen de firma",
    cancel: "Cancelar",
    back: "Atrás",
    next: "Siguiente",
    sendCodeToMyEmail: "Enviar código a mi correo electrónico",
    sendCodeToMyPhone: "Enviar código a mi teléfono",
    verifyDescription:
        "Para garantizar la seguridad de su información, necesitamos verificar su identidad. Puede optar por recibir un código de verificación por teléfono o correo electrónico. Seleccione su método preferido.",
    selfieDescription:
        "Como parte de nuestro compromiso de garantizar el más alto nivel de seguridad y protección de la identidad, requerimos una última prueba para confirmar su identidad.",
    signatureImageDrawingTip: "No dibujes tu firma sobre el código QR",
    signatureImageDescription: "Dibuja la imagen de tu firma sobre el cuadro",
    clearSignature: "Limpiar",
    note: "Nota",
    sendCode: "Enviar código",
    verifyCodeDescription:
        "Queremos asegurarnos de que seas realmente tú. Para verificar aún más su identidad, ingrese el código de verificación que se envió a {{destination}}.",
    confirm: "Confirmar",
    resendCode: "Reenviar codigo",
    code: "Código",
    phone: "Teléfono",
};
