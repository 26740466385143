import { SIGNING_PORTAL_AUTH_TOKEN_KEY } from "../../../utils/constants";

import { updateState } from "../utils";
import * as actionTypes from "../actionTypes";

const initialState = {
    token: localStorage.getItem(SIGNING_PORTAL_AUTH_TOKEN_KEY) || null,
    userData: null,
};

const updateToken = (state, action) => {
    return updateState(state, {
        token: action.token,
    });
};

const updateUserData = (state, action) => {
    return updateState(state, {
        userData: action.userData,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_TOKEN:
            return updateToken(state, action);
        case actionTypes.UPDATE_USER_DATA:
            return updateUserData(state, action);
        default:
            return state;
    }
};

export default reducer;
