export const TRANSLATIONS_PT = {
    // RESPONSE MESSAGE
    UNAUTHORIZED: "Não autorizado",
    TOKEN_EXPIRED: "Sessão expirada",
    NOT_FOUND: "Serviço não encontrado",
    ACCOUNT_NOT_ACTIVATED: "Por favor, ative sua conta em seu e-mail",
    BAD_REQUEST: "Pedido ruim",
    SERVICE_ERROR: "Erro de serviço",
    NETWORK_ERROR:
        "Desculpe, estamos fazendo uma melhoria no sistema, incorporamos novos recursos e algumas atualizações de segurança, nos dê mais alguns minutos para terminar. Em breve você poderá apreciá-los.",
    WENT_WRONG: "Algo deu errado, tente novamente",
    BLANK_IMAGE_UPLOAD_ERROR: "Imagem em branco não pode ser carregada",
    ERROR_DUPLICATED_EMAIL: "Já existe um usuário com o mesmo e-mail",
    ERROR_DOCUMENT_NOT_SIGNED: "Documento não assinado",
    ERROR_DOCUMENT_NOT_FOUND: "Documento não encontrado",
    ERROR_DOCUMENT_DELETED: "O documento foi excluído pelo remetente",
    ERROR_API: "Erro de API",
    ERROR_DOCUMENT_SIZE_LARGE: "O tamanho do documento é muito grande",
    ERROR_FILE_SIZE_LARGE: "O tamanho do arquivo é muito grande",
    ERROR_NOT_READY_SIGN: "Documento não pronto para assinar",
    ERROR_ALREADY_IN_PROCESS: "O documento já está em processamento",
    ERROR_GETTING_SIGNED_DOCUMENT: "Falha ao obter documento assinado",
    ERROR_INVALID_OTP: "OTP inválido",
    ERROR_DOCUMENT_ALREADY_FAILED: "O status do documento já falhou",
    ERROR_NOT_READY_OTP: "Não está pronto para enviar OTP, primeiro crie uma solicitação",
    ERROR_NO_PERMISSION: "Você não tem permissão",
    ERROR_EMAIL_NOT_EXIST: "Email não existe",
    ERROR_INVALID_CREDENTIAL: "Email ou senha são inválidos",
    ERROR_WRONG_PASSWORD: "Senha incorreta",
    ERROR_IP_DIFF: "Problema de segurança detectado",
    ERROR_USER_NOT_FOUND: "Usuário não encontrado",
    ERROR_CODE_EXPIRED: "O código de verificação expirou",
    ERROR_INVALID_VERIFICATION_CODE: "Código de verificação inválido",
    ERROR_ALREADY_SIGNED_REJECTED: "Documento já assinado ou rejeitado",
    ERROR_NO_CERTIFICATION: "Sem certificado",
    ERROR_INVALID_GUID: "GUID inválido",
    ERROR_SUBSCRIPTION_EXPIRED: "Sua assinatura expirou",
    ERROR_NOT_ALLOWED_TO_USE_OPTION: "Você não tem permissão para usar esta opção com seu plano",
    ERROR_TEMPLATE_NOT_FOUND: "O modelo não existe",
    ERROR_NO_STRIPE_CUSTOMER: "Você não está inscrito no Stripe",
    ERROR_NO_CHAT_ADMIN:
        "Você não é um administrador. Você não tem permissão para recuperar a lista de administradores",
    ERROR_NO_STORAGE:
        "Você esgotou o armazenamento ou o número do documento, atualize seu plano ou entre em contato com o suporte",
    ERROR_INVALID_REQUEST_DATA: "Os dados da solicitação não são válidos, verifique sua solicitação e tente novamente",
    ERROR_NO_DOCUMENT_NUMBER:
        "Esta função está desativada porque você ficou sem assinaturas, compre mais documentos ou atualize seu plano",
    ERROR_NOT_ALLOWED_TO_CREATE_SIGN_FLUX:
        "Você não tem permissão para criar fluxo de sinal, entre em contato com seu administrador",
    INVALID_DOCUMENT_STATUS: "O documento não está disponível para iniciar a assinatura",
    ERROR_NOT_ALLOWED_TO_REGISTER_DEVICE:
        "Você não tem permissão para usar a função do dispositivo, atualize seu plano ou entre em contato com o suporte",
    ERROR_TABLET_NOT_FOUND: "O tablet com este ID de dispositivo não existe ou você não é o proprietário",
    ERROR_NO_SIGNATURE_IMAGE: "Sem imagem de assinatura",
    ERROR_DOCUMENT_DELETED_OR_SIGN_CANCELLED: "O documento foi excluído ou o fluxo de sinais foi cancelado",
    ERROR_NOT_ALLOWED_USE_CERTIFICATE:
        "Você não tem permissão para usar a funcionalidade de certificado de nuvem em seu plano, atualize-o",
    ERROR_NOT_ADMIN: "Você não é administrador da conta",
    ERROR_CONTACT_NOT_FOUND: "Contato não encontrado",
    ERROR_INVALID_DISCOUNT_CODE: "Código de desconto inválido",
    ERROR_NOT_DOCUMENT_OWNER: "Você não é o proprietário deste documento",
    ERROR_NOT_ALLOWED_TO_USE_TEMPLATE:
        "Você não tem permissão para usar a funcionalidade de modelo inteligente, atualize seu plano ou entre em contato com o suporte",
    ERROR_NOT_ALLOWED_TO_USE_SMART_FLUX:
        "Você não tem permissão para usar a funcionalidade de fluxo inteligente, atualize o plano ou entre em contato com o suporte",
    ERROR_NOT_DOCUMENT_SENDER: "Você não é o remetente deste documento, você não tem permissão",
    ERROR_NOT_ALLOWED_LOCAL_CERTIFICATE:
        "Você não tem permissão para usar a funcionalidade de certificado local, atualize seu plano ou entre em contato com o suporte",
    ERROR_SIGN_FLOW: "Fluxo de sinal inválido",
    ERROR_SIGN_METHOD: "Método de assinatura inválido",
    ERROR_SIGN_FLOW_LEGALE:
        "Você não tem permissão para enviar documentos para outro usuário legale, atualize seu plano ou entre em contato com o suporte",
    ERROR_SIGN_FLOW_APPROVE:
        "Você não tem permissão para enviar documento para aprovação, atualize seu plano ou entre em contato com o suporte",
    ERROR_SIGN_FLOW_TABLET:
        "Você não tem permissão para usar o fluxo do dispositivo móvel, atualize seu plano ou entre em contato com o suporte",
    ERROR_SIGNATURE_NUMBER_SMS:
        "Você não tem número de assinatura suficiente para assinar SMS remoto ou não tem permissão para usar este método de assinatura",
    ERROR_SIGNATURE_NUMBER_NO_SMS:
        "Você não tem número de assinatura suficiente para assinatura remota ou não tem permissão para usar este método de assinatura",
    ERROR_SIGNATURE_NUMBER_LOCAL_CERTIFICATE:
        "Você não tem número de assinatura suficiente para assinar o certificado local ou não tem permissão para usar este método de assinatura",
    ERROR_SIGNATURE_NUMBER_CERTIFICATION:
        "Você não tem número de assinatura suficiente para assinar o certificado em nuvem ou não tem permissão para usar este método de assinatura",
    ERROR_SIGNATURE_NUMBER_STAMP:
        "Você não tem número de assinatura suficiente para o carimbo ou não tem permissão para usar este método de assinatura",
    ERROR_SIGNATURE_NUMBER_TABLET_SIGN:
        "Você não tem número de assinatura suficiente para assinar o dispositivo móvel ou não tem permissão para usar este método de assinatura",
    ERROR_SIGNATURE_NUMBER_VIDEO_VALIDATION:
        "Você não tem um número suficiente para a verificação por vídeo ou não tem permissão para usar este método de verificação",
    ERROR_SIGNATURE_NUMBER_SELFIE_VALIDATION:
        "Você não tem número suficiente para verificação de selfie ou não tem permissão para usar este método de verificação",
    ERROR_SIGNATURE_NUMBER_ID_VALIDATION:
        "Você não tem número suficiente para verificação de identidade ou não tem permissão para usar este método de verificação",
    ERROR_LEGALE_NOT_FOUND: "O usuário Legale, para quem você vai enviar o documento para assinar não existe",
    ERROR_DEVICE_NOT_FOUND: "O dispositivo móvel, para o qual você enviará o documento para assinar, não existe",
    ERROR_SIGNATURE_IMAGE_POSITION: "Posição da imagem de assinatura inválida",
    ERROR_NOT_ALLOWED_SECONDARY_USER:
        "Você não é o administrador da conta ou não tem permissão para usar a função de usuário secundário",
    ERROR_COUPON_ALREADY_USED: "Você já usou este código de desconto",
    ERROR_LINK_INVALID: "Este link é inválido",
    ERROR_LINK_EXPIRED: "Este link expirou",
    ERROR_NOT_OWNER: "Você não é dono",
    ERROR_CONTACT_DUPLICATED: "O contato com o mesmo e-mail já existe",
    ERROR_DOCUMENT_SIGN_EXPIRED: "O fluxo de assinatura do documento expirou, você não pode continuar este fluxo",
    ERROR_SMART_FORM_LINK_EXPIRED: "Este link expirou e não está mais disponível",
    ERROR_FOLDER_CREATE_NOT_ALLOWED: "Você não tem permissão para criar pasta",
    ERROR_FOLDER_RENAME_NOT_ALLOWED: "Você não tem permissão para renomear a pasta",
    ERROR_FOLDER_DELETE_NOT_ALLOWED: "Você não tem permissão para excluir a pasta",
    ERROR_DOCUMENT_RENAME_NOT_ALLOWED: "Você não tem permissão para renomear o documento",
    ERROR_DOCUMENT_DELETE_NOT_ALLOWED: "Você não tem permissão para excluir o documento",
    ERROR_NOT_AVAILABLE_TO_UPLOAD_TO_FOLDER:
        "Não disponível para fazer upload do documento para a pasta atual, está cheio",
    ERROR_INVALID_REMOTE_PATH: "O caminho remoto é inválido",
    ERROR_CSV_IS_NOT_MATCHED: "O formato CSV não corresponde à atualização",
    ERROR_DUPLICATED_FOLDER_NAME: "Nome da pasta duplicado",
    ERROR_DOCUMENT_BROKEN: "O documento está quebrado ou danificado, não é possível iniciar o fluxo de sinalização",

    SUCCESS_PURCHASE: "Compra feita com sucesso",
    SUCCESS_SENT_OTP: "OTP foi enviado para o seu telefone, verifique o seu SMS",
    SUCCESS_OTP: "OTP enviado com sucesso",
    SUCCESS_SUBSCRIBED: "Inscrito com sucesso",
    SUCCESS_RESENT: "Reenviado com sucesso",
    SUCCESS_SIGNER_REMOVED: "Signatário removido com sucesso",
    SUCCESS_DOCUMENT_SENT: "Documento enviado com sucesso",
    SUCCESS_DOCUMENT_SENT_TO_EMAIL: "Documento a ser assinado foi enviado para o e-mail",
    SUCCESS_DOCUMENT_RESENT: "Documento reenviado com sucesso",
    SUCCESS_SIGNED: "Assinado com sucesso",
    SUCCESS_DOCUMENT_SHARED: "Documento compartilhado com sucesso",
    SUCCESS_UPDATE_PROFILE: "Atualizar perfil com sucesso",
    SUCCESS_UPLOAD_USER_VIDEO: "Carregar vídeo com sucesso",
    SUCCESS_UPLOAD_SIGNATURE_IMAGE: "Carregar imagem de assinatura com sucesso",
    SUCCESS_UPLOAD_CERTIFICATION: "Certificado carregado com sucesso",
    SUCCESS_UPDATED_CERTIFICATION: "Senha atualizada com sucesso",
    SUCCESS_REMOVED_CERTIFICATION: "Certificado removido com sucesso",
    SUCCESS_UPDATED_CONFIGURATION: "Atualizar configuração com sucesso",
    SUCCESS_UPDATED_PASSWORD: "Atualizar senha com sucesso",
    SUCCESS_ADD_SIGNATURE: "Adicionar número de assinatura com sucesso",
    SUCCESS_CREATED_USER: "Usuário criado com sucesso",
    SUCCESS_UPDATED_USER: "Usuário atualizado com sucesso",
    SUCCESS_DELETED_USER: "Usuário excluído com sucesso",
    SUCCESS_CREATED_CONTACT: "Contato criado com sucesso",
    SUCCESS_UPDATED_CONTACT: "Contato atualizado com sucesso",
    SUCCESS_DELETED_CONTACT: "Contato excluído com sucesso",
    SUCCESS_DELETED_CONTACTS: "Contatos excluídos com sucesso",
    SUCCESS_IMPORTED_CONTACT: "Contato importado com sucesso",
    SUCCESS_UPLOADED_DOCUMENT: "Documento carregado com sucesso",
    SUCCESS_UPLOADED_FILE: "Arquivos enviados com sucesso",
    SUCCESS_UPDATED_DOCUMENT: "Documento atualizado com sucesso",
    SUCCESS_DELETED_DOCUMENT: "Documento excluído com sucesso",
    SUCCESS_REJECTED: "Rejeitado com sucesso",
    SUCCESS_UPLOADED_ATTACHED_FILE: "Arquivo anexado carregado com sucesso",
    SUCCESS_DELETED_ATTACHED_FILE: "Arquivo anexado excluído com sucesso",
    SUCCESS_PAUSED_FLOW: "Fluxo pausado com sucesso",
    SUCCESS_SIGNED_DOCUMENT: "Documento assinado com sucesso",
    SUCCESS_PLAY_FLOW: "Fluxo reproduzido novamente com sucesso",
    SUCCESS_SIGN_PROCESS_STARTED: "Assinar discussão iniciada",
    SUCCESS_APPROVED_DOCUMENT: "Documento aprovado com sucesso",
    SUCCESS_APPLIED: "Aplicado com sucesso",
    SUCCESS_SIGN_BACKGROUND: "O processo de inscrição está em segundo plano, você receberá o status por e-mail",
    SUCCESS_DELETED_TABLET: "Tablet excluído com sucesso",
    SUCCESS_CANCEL_SUBSCRIPTION: "Assinatura cancelada com sucesso",
    SUCCESS_CREATE_CUSTOMIZE_PLAN: "Você receberá contato em breve",
    SUCCESS_LINK_COPIED_TO_CLIPBOARD: "O link do formulário foi copiado para sua área de transferência",
    SUCCESS_CSV_LINK_TEMPLATE: "CSV foi vinculado ao modelo com sucesso",
    SUCCESS_CSV_WORKFLOW_UPDATE: "O fluxo de trabalho CSV foi atualizado com sucesso",
    SUCCESS_CSV_ALARM_UPDATE: "O alarme CSV foi atualizado com sucesso",
    SUCCESS_CSV_WORKFLOW_LAUNCH: "O fluxo de trabalho CSV foi iniciado com sucesso",
    SUCCESS_SAVED: "Salvo com sucesso",

    CONFIRM_REMOVE_CONTACT: "Tem certeza que deseja remover este contato?",
    CONFIRM_REMOVE_CONTACTS: "Tem certeza de que deseja remover esses contatos?",
    CONFIRM_SET_AS_EXTERNAL_CONTACTS: "Tem certeza de definir esses contatos como usuário externo?",
    CONFIRM_APPROVE_DOCUMENT: "Tem certeza de que aprova este documento?",
    CONFIRM_REPLACE_VIDEO_VALIDATION:
        "Este documento já possui a validação de vídeo, tem certeza que deseja substituí-la?",
    CONFIRM_REPLACE_SELFIE_VALIDATION:
        "Este documento já contém a imagem da selfie, tem certeza de que deseja substituí-la?",
    CONFIRM_REPLACE_ID_VALIDATION:
        "Este documento já contém a imagem do documento, tem certeza de que deseja substituí-la?",
    CONFIRM_REMOVE_CERTIFICATION: "Tem certeza de que deseja remover esta certificação?",
    CONFIRM_REMOVE_SIGNER: "Tem certeza de que deseja remover este signatário?",
    CONFIRM_DELETE_DOCUMENT: "Tem certeza de que deseja excluir este documento?",
    CONFIRM_NOTIFICATION_MULTIPLE: "Tem certeza de enviar uma notificação para esses signatários?",
    CONFIRM_DELETE_DOCUMENT_MULTIPLE: "Tem certeza de excluir os documentos selecionados?",
    CONFIRM_PAUSE_DOCUMENT: "Tem certeza de que deseja pausar o fluxo de assinatura deste documento?",
    CONFIRM_END_SIGN_FLOW: "Tem certeza de que deseja encerrar este fluxo de sinalização?",
    CONFIRM_CONTINUE_FLOW: "Tem certeza de que deseja continuar este fluxo de sinalização?",
    CONFIRM_DELETE_FILE: "Tem certeza que deseja excluir este arquivo?",
    CONFIRM_REMOVE_USER: "Tem certeza que deseja remover este usuário?",
    CONFIRM_REMOVE_TABLET: "Tem certeza de que deseja remover este tablet?",
    CONFIRM_REPLACE_USER_VIDEO: "Tem certeza de substituir o vídeo?",
    CONFIRM_MAKE_AS_ADMIN: "Tem certeza de tornar este usuário como administrador?",
    CONFIRM_REMOVE_FLUX: "Tem certeza de que deseja excluir este fluxo?",
    CONFIRM_SEND_DOCUMENT_USE_FLUX: "Tem certeza de enviar esses documentos usando esse fluxo?",
    CONFIRM_REMOVE_TEMPLATE: "Tem certeza de que deseja excluir este modelo?",
    CONFIRM_DUPLICATE_TEMPLATE: "Tem certeza de duplicar este modelo?",
    CONFIRM_CANCEL_SUBSCRIPTION: "Tem certeza de que deseja cancelar a assinatura?",
    CONFIRM_MAKE_AS_CUSTOMIZED_PLAN: "Tem a certeza de atribuir um plano personalizado a este cliente?",
    CONFIRM_DELETE_FOLDER: "Tem certeza de que deseja excluir esta pasta?",
    CONFIRM_REMOVE_FORM: "Tem certeza de que deseja excluir este formulário?",
    CONFIRM_DELETE_ALARM: "Tem certeza de que deseja excluir este alarme?",
    CONFIRM_DELETE: "Você tem certeza que quer deletar?",
    CONFIRM_LAUNCH_WORKFLOW: "Você tem certeza de iniciar o Workflow?",
    CONFIRM_SAVE_TEMPLATE: "Ao salvar, você criará uma nova versão, tem certeza de fazer isso?",
    CONFIRM_LAUNCH_MULTIPLE_CSVS_WORKFLOW: "Tem certeza de iniciar o fluxo de trabalho desses csvs?",
    CONFIRM_UNGROUP_TEMPLATES: "Tem certeza de desagrupar esses modelos?",

    success: "Sucesso",
    error: "Erro",
    warning: "Aviso",

    // LAYOUT
    footer1: "legale.io é uma empresa Despapeliza Llc. 7345 W Sand Lake RD STE 210, OFC 6837, Orlando, FL, USA",
    footer2: "Todos os direitos reservados legale",
    signOut: "Sair",

    // LOGIN
    login: "Entrar",
    email: "E-mail",
    password: "Senha",
    fieldRequired: "Este campo é obrigatório",
    fieldInvalid: "Valor inválido",
    fileLengthError: "O nome do arquivo deve ter menos de {{limit}} caracteres",
    submit: "Enviar",

    // HOME
    searchDocumentByNationalIDNumber: "Pesquise seu documento por número de identificação nacional",
    sign: "Assinar",
    noSearchResult: "Nenhum documento encontrado com suas informações",
    searchTip:
        "Para localizar o documento que aguarda sua assinatura, é necessário seu número de identificação. Este identificador exclusivo garante que você corresponda com precisão ao documento correto. Tenha em mãos o seu número de identificação e insira-o quando solicitado.",
    searchTipNoResult:
        "Encontre seu próprio documento de contrato para assinar com seu número de identificação nacional",
    fileName: "Nome do arquivo",
    subject: "Sujeito",
    description: "Descrição",
    backToSearch: "Voltar à pesquisa",

    // SIGN
    pdfPaginationDescription: "Página {{pageNumber}} de {{totalPage}}",
    signatureImage: "Imagem de assinatura",
    cancel: "Cancelar",
    back: "Voltar",
    next: "Próximo",
    sendCodeToMyEmail: "Enviar código para meu e-mail",
    sendCodeToMyPhone: "Enviar código para meu telefone",
    verifyDescription:
        "Para garantir a segurança de suas informações, precisamos verificar sua identidade. Você pode optar por receber um código de verificação por telefone ou e-mail. Selecione seu método preferido.",
    selfieDescription:
        "Como parte do nosso compromisso de garantir o mais alto nível de segurança e proteção de identidade, exigimos uma última prova para confirmar a sua identidade.",
    signatureImageDrawingTip: "Não desenhe sua assinatura sobre o código QR",
    signatureImageDescription: "Desenhe a imagem da sua assinatura sobre a caixa",
    clearSignature: "Assinatura clara",
    note: "Observação",
    sendCode: "Enviar código",
    verifyCodeDescription:
        "Queremos ter certeza de que é realmente você. Para verificar ainda mais sua identidade, insira o código de verificação que foi enviado para {{destination}}.",
    confirm: "Confirme",
    resendCode: "Reenviar código",
    code: "Código",
    phone: "Telefone",
};
