import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Camera, { FACING_MODES } from "react-html5-camera-photo";

import "react-html5-camera-photo/build/css/index.css";

const propTypes = {
    takePhotoHandler: PropTypes.func.isRequired,
};

const LegaleCamera = ({ takePhotoHandler }) => {
    return (
        <Wrapper>
            <Camera
                onTakePhoto={takePhotoHandler}
                isMaxResolution
                idealFacingMode={FACING_MODES.ENVIRONMENT}
                isImageMirror
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: relative;
`;

LegaleCamera.propTypes = propTypes;

export default LegaleCamera;
