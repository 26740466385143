import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { BsFullscreen, BsFullscreenExit } from "react-icons/bs";

const propTypes = {
    page: PropTypes.number,
    numPages: PropTypes.number,
    scale: PropTypes.number,
    setScale: PropTypes.func,
    fullScreen: PropTypes.bool,
    enterFullScreenHandler: PropTypes.func,
    url: PropTypes.string,
    fileName: PropTypes.string,
};

const Toolbar = ({ page, numPages, scale, setScale, fullScreen, enterFullScreenHandler, url, fileName }) => {
    return (
        <Wrapper className="toolbar">
            <div className="pdf-info">
                <div className="page-info">
                    {page} / {numPages}
                </div>
                <div className="divider" />
                <div className="scale-info">
                    <MinusCircleOutlined
                        className={`scale-icon ${scale === 1 ? "disabled" : "'"}`}
                        onClick={() => setScale(scale > 1 ? scale - 0.5 : 1)}
                    />
                    <span className="mr-2 ml-2">X {scale}</span>
                    <PlusCircleOutlined
                        className={`scale-icon ${scale === 5 ? "disabled" : "'"}`}
                        onClick={() => setScale(scale < 5 ? scale + 0.5 : 5)}
                    />
                </div>
            </div>
            <div className="action-box">
                {fullScreen ? (
                    <BsFullscreenExit className="cursor-pointer" onClick={() => enterFullScreenHandler(false)} />
                ) : (
                    <BsFullscreen className="cursor-pointer" onClick={() => enterFullScreenHandler(true)} />
                )}
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    color: rgba(0, 0, 0, 0.85);
    background-color: rgb(248, 230, 204);

    .pdf-info {
        .divider {
            border-left: 1px solid rgba(0, 0, 0, 0.85);
        }
    }
`;

Toolbar.propTypes = propTypes;

export default Toolbar;
