export const TRANSLATIONS_FR = {
    // RESPONSE MESSAGE
    UNAUTHORIZED: "Non autorisé",
    TOKEN_EXPIRED: "La session a expiré",
    ACCOUNT_NOT_ACTIVATED: "Veuillez activer votre compte sur votre email",
    NOT_FOUND: "Service non trouvé",
    BAD_REQUEST: "Mauvaise demande",
    SERVICE_ERROR: "Erreur de service",
    NETWORK_ERROR:
        "Désolé, nous apportons une amélioration au système, nous avons intégré de nouvelles fonctionnalités et quelques mises à jour de sécurité, donnez-nous quelques minutes de plus pour terminer. Bientôt vous pourrez en profiter.",
    WENT_WRONG: "Quelque chose s'est mal passé essaie encore",
    BLANK_IMAGE_UPLOAD_ERROR: "L'image vierge ne peut pas être téléchargée",
    ERROR_DUPLICATED_EMAIL: "L'utilisateur avec le même e-mail existe déjà",
    ERROR_DOCUMENT_NOT_SIGNED: "Document non signé",
    ERROR_DOCUMENT_NOT_FOUND: "Document introuvable",
    ERROR_DOCUMENT_DELETED: "Le document a été supprimé par l'expéditeur",
    ERROR_API: "Erreur API",
    ERROR_DOCUMENT_SIZE_LARGE: "La taille du document est trop grande",
    ERROR_FILE_SIZE_LARGE: "La taille du fichier est trop grande",
    ERROR_NOT_READY_SIGN: "Document pas prêt à signer",
    ERROR_ALREADY_IN_PROCESS: "Le document est déjà en cours de traitement",
    ERROR_GETTING_SIGNED_DOCUMENT: "Échec de la signature du document",
    ERROR_INVALID_OTP: "OTP non valide",
    ERROR_DOCUMENT_ALREADY_FAILED: "L'état du document a déjà échoué",
    ERROR_NOT_READY_OTP: "Pas prêt à envoyer OTP, veuillez d'abord créer une demande",
    ERROR_NO_PERMISSION: "Vous n'avez aucune autorisation",
    ERROR_EMAIL_NOT_EXIST: "L'e-mail n'existe pas",
    ERROR_INVALID_CREDENTIAL: "L'email ou le mot de passe ne sont pas valides",
    ERROR_WRONG_PASSWORD: "Mauvais mot de passe",
    ERROR_IP_DIFF: "Problème de sécurité détecté",
    ERROR_USER_NOT_FOUND: "Utilisateur non trouvé",
    ERROR_CODE_EXPIRED: "Le code de vérification a expiré",
    ERROR_INVALID_VERIFICATION_CODE: "Code de vérification invalide",
    ERROR_ALREADY_SIGNED_REJECTED: "Le document a déjà été signé ou rejeté",
    ERROR_NO_CERTIFICATION: "Pas de certificat",
    ERROR_INVALID_GUID: "GUID non valide",
    ERROR_SUBSCRIPTION_EXPIRED: "Votre abonnement est expiré",
    ERROR_NOT_ALLOWED_TO_USE_OPTION: "Vous n'êtes pas autorisé à utiliser cette option avec votre plan",
    ERROR_TEMPLATE_NOT_FOUND: "Le modèle n'existe pas",
    ERROR_NO_STRIPE_CUSTOMER: "Vous n'êtes pas abonné à Stripe",
    ERROR_NO_CHAT_ADMIN:
        "Vous n'êtes pas un administrateur. Vous n'êtes pas autorisé à récupérer la liste des administrateurs",
    ERROR_NO_STORAGE:
        "Vous manquez de stockage ou de numéro de document, veuillez mettre à niveau votre plan ou contacter l'assistance",
    ERROR_INVALID_REQUEST_DATA:
        "Les données de la demande ne sont pas valides, veuillez vérifier votre demande et réessayer",
    ERROR_NO_DOCUMENT_NUMBER:
        "Cette fonction est désactivée car vous n'avez plus de signatures, veuillez acheter plus de documents ou mettre à niveau votre plan",
    ERROR_NOT_ALLOWED_TO_CREATE_SIGN_FLUX:
        "Vous n'êtes pas autorisé à créer un flux de signature, veuillez contacter votre administrateur",
    INVALID_DOCUMENT_STATUS: "Le document n'est pas disponible pour commencer à signer",
    ERROR_NOT_ALLOWED_TO_REGISTER_DEVICE:
        "Vous n'êtes pas autorisé à utiliser la fonction de l'appareil, veuillez mettre à jour votre forfait ou contacter l'assistance",
    ERROR_TABLET_NOT_FOUND: "La tablette avec cet ID d'appareil n'existe pas ou vous n'en êtes pas le propriétaire",
    ERROR_NO_SIGNATURE_IMAGE: "Aucune image de signature",
    ERROR_DOCUMENT_DELETED_OR_SIGN_CANCELLED: "Le document a été supprimé ou le flux de signatures a été annulé",
    ERROR_NOT_ALLOWED_USE_CERTIFICATE:
        "Vous n'êtes pas autorisé à utiliser la fonctionnalité de certificat cloud dans votre plan, veuillez le mettre à niveau",
    ERROR_NOT_ADMIN: "Vous n'êtes pas administrateur du compte",
    ERROR_CONTACT_NOT_FOUND: "Contact introuvable",
    ERROR_INVALID_DISCOUNT_CODE: "Code de réduction invalide",
    ERROR_NOT_DOCUMENT_OWNER: "Vous n'êtes pas propriétaire de ce document",
    ERROR_NOT_ALLOWED_TO_USE_TEMPLATE:
        "Vous n'êtes pas autorisé à utiliser la fonctionnalité de modèle intelligent, veuillez mettre à niveau votre plan ou contacter le support",
    ERROR_NOT_ALLOWED_TO_USE_SMART_FLUX:
        "Vous n'êtes pas autorisé à utiliser la fonctionnalité smart flux, veuillez mettre à niveau le plan ou contacter le support",
    ERROR_NOT_DOCUMENT_SENDER: "No eres el remitente de este documento, no tienes permiso",
    ERROR_NOT_ALLOWED_LOCAL_CERTIFICATE:
        "Vous n'êtes pas autorisé à utiliser la fonctionnalité de certificat local, veuillez mettre à niveau votre plan ou contacter le support",
    ERROR_SIGN_FLOW: "Flux de signes invalide",
    ERROR_SIGN_METHOD: "Méthode de signe invalide",
    ERROR_SIGN_FLOW_LEGALE:
        "Vous n'êtes pas autorisé à envoyer un document à un autre utilisateur légal, veuillez mettre à niveau votre plan ou contacter le support",
    ERROR_SIGN_FLOW_APPROVE:
        "Vous n'êtes pas autorisé à envoyer un document à approuver, veuillez mettre à niveau votre plan ou contacter l'assistance",
    ERROR_SIGN_FLOW_TABLET:
        "Vous n'êtes pas autorisé à utiliser Mobile Device Flux, veuillez mettre à niveau votre forfait ou contacter l'assistance",
    ERROR_SIGNATURE_NUMBER_SMS:
        "Vous n'avez pas assez de numéro de signature pour la signature SMS à distance ou vous n'êtes pas autorisé à utiliser cette méthode de signature",
    ERROR_SIGNATURE_NUMBER_NO_SMS:
        "Vous n'avez pas assez de numéro de signature pour la signature à distance ou vous n'êtes pas autorisé à utiliser cette méthode de signature",
    ERROR_SIGNATURE_NUMBER_LOCAL_CERTIFICATE:
        "Vous n'avez pas assez de numéro de signature pour la signature du certificat local ou vous n'êtes pas autorisé à utiliser cette méthode de signature",
    ERROR_SIGNATURE_NUMBER_CERTIFICATION:
        "Vous n'avez pas assez de numéro de signature pour signer le certificat Cloud ou vous n'êtes pas autorisé à utiliser cette méthode de signature",
    ERROR_SIGNATURE_NUMBER_STAMP:
        "Vous n'avez pas assez de numéro de signature pour le signe du tampon ou vous n'êtes pas autorisé à utiliser cette méthode de signe",
    ERROR_SIGNATURE_NUMBER_TABLET_SIGN:
        "Vous n'avez pas assez de numéro de signature pour signer un appareil mobile ou vous n'êtes pas autorisé à utiliser cette méthode de signature",
    ERROR_SIGNATURE_NUMBER_VIDEO_VALIDATION:
        "Vous n'avez pas assez de numéro pour la vérification vidéo ou vous n'êtes pas autorisé à utiliser cette méthode de vérification",
    ERROR_SIGNATURE_NUMBER_SELFIE_VALIDATION:
        "Vous n'avez pas assez de numéro pour la vérification Selfie ou vous n'êtes pas autorisé à utiliser cette méthode de vérification",
    ERROR_SIGNATURE_NUMBER_ID_VALIDATION:
        "Vous n'avez pas assez de numéro pour la vérification d'identité ou vous n'êtes pas autorisé à utiliser cette méthode de vérification",
    ERROR_LEGALE_NOT_FOUND: "L'utilisateur légal, à qui vous allez envoyer le document à signer n'existe pas",
    ERROR_DEVICE_NOT_FOUND: "L'appareil mobile auquel vous allez envoyer le document à signer n'existe pas",
    ERROR_SIGNATURE_IMAGE_POSITION: "Position de l'image de la signature non valide",
    ERROR_NOT_ALLOWED_SECONDARY_USER:
        "Vous n'êtes pas l'administrateur du compte ou vous n'êtes pas autorisé à utiliser la fonction d'utilisateur secondaire",
    ERROR_COUPON_ALREADY_USED: "Vous avez déjà utilisé ce code de réduction",
    ERROR_LINK_INVALID: "Ce lien est invalide",
    ERROR_LINK_EXPIRED: "Ce lien est expiré",
    ERROR_NOT_OWNER: "Vous n'êtes pas propriétaire",
    ERROR_CONTACT_DUPLICATED: "Un contact avec la même adresse e-mail existe déjà",
    ERROR_DOCUMENT_SIGN_EXPIRED: "Le flux de signature de document a expiré, vous ne pouvez pas continuer ce flux",
    ERROR_SMART_FORM_LINK_EXPIRED: "Ce lien a expiré et n'est plus disponible",
    ERROR_FOLDER_CREATE_NOT_ALLOWED: "Vous n'êtes pas autorisé à créer un dossier",
    ERROR_FOLDER_RENAME_NOT_ALLOWED: "Vous n'êtes pas autorisé à renommer le dossier",
    ERROR_FOLDER_DELETE_NOT_ALLOWED: "Vous n'êtes pas autorisé à supprimer le dossier",
    ERROR_DOCUMENT_RENAME_NOT_ALLOWED: "Vous n'êtes pas autorisé à renommer le document",
    ERROR_DOCUMENT_DELETE_NOT_ALLOWED: "Vous n'êtes pas autorisé à supprimer le document",
    ERROR_NOT_AVAILABLE_TO_UPLOAD_TO_FOLDER:
        "Non disponible pour télécharger le document dans le dossier actuel, il est plein",
    ERROR_INVALID_REMOTE_PATH: "Le chemin distant n'est pas valide",
    ERROR_CSV_IS_NOT_MATCHED: "Le format CSV ne correspond pas à la mise à jour",
    ERROR_DUPLICATED_FOLDER_NAME: "Nom du dossier dupliqué",
    ERROR_DOCUMENT_BROKEN: "Le document est cassé ou endommagé, vous ne pouvez pas démarrer le flux de signatures",

    SUCCESS_PURCHASE: "L'achat est effectué avec succès",
    SUCCESS_SENT_OTP: "OTP a été envoyé sur votre téléphone, veuillez vérifier votre SMS",
    SUCCESS_OTP: "OTP envoyé avec succès",
    SUCCESS_SUBSCRIBED: "Abonné avec succès",
    SUCCESS_RESENT: "Renvoyer avec succès",
    SUCCESS_SIGNER_REMOVED: "Signataire supprimé avec succès",
    SUCCESS_DOCUMENT_SENT: "Document envoyé avec succès",
    SUCCESS_DOCUMENT_SENT_TO_EMAIL: "Le document à signer a été envoyé à l'adresse e-mail",
    SUCCESS_DOCUMENT_RESENT: "Document renvoyé avec succès",
    SUCCESS_SIGNED: "Signé avec succès",
    SUCCESS_DOCUMENT_SHARED: "Document partagé avec succès",
    SUCCESS_UPDATE_PROFILE: "Mettre à jour le profil avec succès",
    SUCCESS_UPLOAD_USER_VIDEO: "Télécharger la vidéo avec succès",
    SUCCESS_UPLOAD_SIGNATURE_IMAGE: "Télécharger l'image de signature avec succès",
    SUCCESS_UPLOAD_CERTIFICATION: "Certificat téléchargé avec succès",
    SUCCESS_UPDATED_CERTIFICATION: "Mot de passe mis à jour avec succès",
    SUCCESS_REMOVED_CERTIFICATION: "Certificat supprimé avec succès",
    SUCCESS_UPDATED_CONFIGURATION: "Mettre à jour la configuration avec succès",
    SUCCESS_UPDATED_PASSWORD: "Mettre à jour le mot de passe avec succès",
    SUCCESS_ADD_SIGNATURE: "Ajouter le numéro de signature avec succès",
    SUCCESS_CREATED_USER: "Utilisateur créé avec succès",
    SUCCESS_UPDATED_USER: "Utilisateur mis à jour avec succès",
    SUCCESS_DELETED_USER: "Utilisateur supprimé avec succès",
    SUCCESS_CREATED_CONTACT: "Contact créé avec succès",
    SUCCESS_UPDATED_CONTACT: "Contact mis à jour avec succès",
    SUCCESS_DELETED_CONTACT: "Contact supprimé avec succès",
    SUCCESS_DELETED_CONTACTS: "Contacts supprimés avec succès",
    SUCCESS_IMPORTED_CONTACT: "Contact importé avec succès",
    SUCCESS_UPLOADED_DOCUMENT: "Document téléchargé avec succès",
    SUCCESS_UPLOADED_FILE: "Fichiers téléchargés avec succès",
    SUCCESS_UPDATED_DOCUMENT: "Document mis à jour avec succès",
    SUCCESS_DELETED_DOCUMENT: "Document supprimé avec succès",
    SUCCESS_REJECTED: "Rejeté avec succès",
    SUCCESS_UPLOADED_ATTACHED_FILE: "Fichier joint téléchargé avec succès",
    SUCCESS_DELETED_ATTACHED_FILE: "Fichier joint supprimé avec succès",
    SUCCESS_PAUSED_FLOW: "Flux mis en pause avec succès",
    SUCCESS_SIGNED_DOCUMENT: "Document signé avec succès",
    SUCCESS_PLAY_FLOW: "Flow a rejoué avec succès",
    SUCCESS_SIGN_PROCESS_STARTED: "Fil de signature démarré",
    SUCCESS_APPROVED_DOCUMENT: "Document approuvé avec succès",
    SUCCESS_APPLIED: "Appliqué avec succès",
    SUCCESS_SIGN_BACKGROUND: "Le processus de signature est en arrière-plan, vous recevrez le statut par e-mail",
    SUCCESS_DELETED_TABLET: "Tablette supprimée avec succès",
    SUCCESS_CANCEL_SUBSCRIPTION: "Abonnement annulé avec succès",
    SUCCESS_CREATE_CUSTOMIZE_PLAN: "Vous serez bientôt contacté",
    SUCCESS_LINK_COPIED_TO_CLIPBOARD: "Le lien du formulaire a été copié dans votre presse-papiers",
    SUCCESS_CSV_LINK_TEMPLATE: "Le CSV est lié au modèle avec succès",
    SUCCESS_CSV_WORKFLOW_UPDATE: "Le flux de travail CSV a été mis à jour avec succès",
    SUCCESS_CSV_ALARM_UPDATE: "L'alarme CSV est mise à jour avec succès",
    SUCCESS_CSV_WORKFLOW_LAUNCH: "Le workflow CSV est lancé avec succès",
    SUCCESS_SAVED: "Enregistré avec succès",

    CONFIRM_REMOVE_CONTACT: "Êtes-vous sûr de supprimer ce contact?",
    CONFIRM_REMOVE_CONTACTS: "Êtes-vous sûr de supprimer ces contacts?",
    CONFIRM_SET_AS_EXTERNAL_CONTACTS: "Êtes-vous sûr de définir ces contacts en tant qu'utilisateur externe ?",
    CONFIRM_APPROVE_DOCUMENT: "Êtes-vous sûr d'approuver ce document?",
    CONFIRM_REPLACE_VIDEO_VALIDATION: "Ce document a déjà la validation vidéo, êtes-vous sûr de le remplacer?",
    CONFIRM_REPLACE_SELFIE_VALIDATION: "Ce document contient déjà l'image selfie, êtes-vous sûr de la remplacer?",
    CONFIRM_REPLACE_ID_VALIDATION: "Ce document contient déjà l'image du document, êtes-vous sûr de la remplacer?",
    CONFIRM_REMOVE_CERTIFICATION: "Êtes-vous sûr de supprimer cette certification?",
    CONFIRM_REMOVE_SIGNER: "Êtes-vous sûr de supprimer ce signataire?",
    CONFIRM_DELETE_DOCUMENT: "Êtes-vous sûr de supprimer ce document?",
    CONFIRM_NOTIFICATION_MULTIPLE: "Êtes-vous sûr d'envoyer une notification à ces signataires ?",
    CONFIRM_DELETE_DOCUMENT_MULTIPLE: "Voulez-vous vraiment supprimer les documents sélectionnés ?",
    CONFIRM_PAUSE_DOCUMENT: "Êtes-vous sûr de suspendre le flux de signatures de ce document?",
    CONFIRM_END_SIGN_FLOW: "Êtes-vous sûr de mettre fin à ce flux de signes?",
    CONFIRM_CONTINUE_FLOW: "Êtes-vous sûr de continuer ce flux de signes?",
    CONFIRM_DELETE_FILE: "Êtes-vous sûr de supprimer ce fichier?",
    CONFIRM_REMOVE_USER: "Êtes-vous sûr de supprimer cet utilisateur?",
    CONFIRM_REMOVE_TABLET: "Êtes-vous sûr de supprimer cette tablette?",
    CONFIRM_REPLACE_USER_VIDEO: "Êtes-vous sûr de remplacer la vidéo?",
    CONFIRM_MAKE_AS_ADMIN: "Êtes-vous sûr de faire de cet utilisateur un administrateur ?",
    CONFIRM_REMOVE_FLUX: "Voulez-vous vraiment supprimer ce flux ?",
    CONFIRM_SEND_DOCUMENT_USE_FLUX: "Êtes-vous sûr d'envoyer ces documents en utilisant ce flux ?",
    CONFIRM_REMOVE_TEMPLATE: "Voulez-vous vraiment supprimer ce modèle ?",
    CONFIRM_DUPLICATE_TEMPLATE: "Êtes-vous sûr de dupliquer ce modèle ?",
    CONFIRM_CANCEL_SUBSCRIPTION: "Êtes-vous sûr d'annuler l'abonnement ?",
    CONFIRM_MAKE_AS_CUSTOMIZED_PLAN: "Êtes-vous sûr d'attribuer un plan sur mesure à ce client ?",
    CONFIRM_DELETE_FOLDER: "Êtes-vous sûr de vouloir supprimer ce dossier ?",
    CONFIRM_REMOVE_FORM: "Êtes-vous sûr de vouloir supprimer ce formulaire ?",
    CONFIRM_DELETE_ALARM: "Voulez-vous vraiment supprimer cette alarme ?",
    CONFIRM_DELETE: "Êtes-vous sûr de vouloir supprimer?",
    CONFIRM_LAUNCH_WORKFLOW: "Êtes-vous sûr de lancer Workflow ?",
    CONFIRM_SAVE_TEMPLATE: "En enregistrant, vous allez créer une nouvelle version, êtes-vous sûr de le faire ?",
    CONFIRM_LAUNCH_MULTIPLE_CSVS_WORKFLOW: "Êtes-vous sûr de lancer le workflow de ces CSV ?",
    CONFIRM_UNGROUP_TEMPLATES: "Êtes-vous sûr de dissocier ces modèles ?",

    success: "Succès",
    error: "Erreur",
    warning: "Avertissement",

    // LAYOUT
    footer1: "legale.io est une société Despapeliza Llc. 7345 W Sand Lake RD STE 210, OFC 6837, Orlando, FL, USA",
    footer2: "Tous droits réservés mentions legale",
    signOut: "Déconnexion",

    // LOGIN
    login: "Connexion",
    email: "E-mail",
    password: "Mot de passe",
    fieldRequired: "Ce champ est obligatoire",
    fieldInvalid: "valeur invalide",
    fileLengthError: "Le nom du fichier doit comporter moins de {{limit}} caractères",
    submit: "Soumettre",

    // HOME
    searchDocumentByNationalIDNumber: "Recherchez votre document par numéro d'identification national",
    sign: "Signe",
    noSearchResult: "Aucun document trouvé avec vos informations",
    searchTip:
        "Pour localiser le document qui attend votre signature, votre numéro d'identification est requis. Cet identifiant unique garantit que nous vous associons avec précision au bon document. Veuillez préparer votre numéro d’identification et saisissez-le lorsque vous y êtes invité.",
    searchTipNoResult:
        "Veuillez trouver votre propre document contractuel à signer avec votre numéro d'identification national",
    fileName: "Nom de fichier",
    subject: "Matière",
    description: "La description",
    backToSearch: "Retour à la recherche",

    // SIGN
    pdfPaginationDescription: "Page {{pageNumber}} de {{totalPage}}",
    signatureImage: "Image de signature",
    cancel: "Annuler",
    back: "Arrière",
    next: "Prochain",
    sendCodeToMyEmail: "Envoyer le code à mon email",
    sendCodeToMyPhone: "Envoyer le code sur mon téléphone",
    verifyDescription:
        "Pour garantir la sécurité de vos informations, nous devons vérifier votre identité. Vous pouvez choisir de recevoir un code de vérification par téléphone ou par e-mail. Veuillez sélectionner votre méthode préférée.",
    selfieDescription:
        "Dans le cadre de notre engagement à garantir le plus haut niveau de sécurité et de protection de votre identité, nous avons besoin d'un dernier élément de preuve pour confirmer votre identité.",
    signatureImageDrawingTip: "Ne dessinez pas votre signature sur le code QR",
    signatureImageDescription: "Veuillez dessiner votre image de signature sur la boîte",
    clearSignature: "Signature claire",
    note: "Noter",
    sendCode: "Envoyer le code",
    verifyCodeDescription:
        "Nous voulons nous assurer qu'il s'agit bien de vous. Afin de vérifier davantage votre identité, saisissez le code de vérification qui a été envoyé à {{destination}}.",
    confirm: "Confirmer",
    resendCode: "Renvoyer le code",
    code: "Code",
    phone: "Téléphone",
};
