export const TRANSLATIONS_IT = {
    // RESPONSE MESSAGE
    UNAUTHORIZED: "Non autorizzato",
    TOKEN_EXPIRED: "Sessione scaduta",
    ACCOUNT_NOT_ACTIVATED: "Si prega di attivare il tuo account alla tua email",
    NOT_FOUND: "Servizio non trovato",
    BAD_REQUEST: "Brutta richiesta",
    SERVICE_ERROR: "Errore di servizio",
    NETWORK_ERROR:
        "Siamo spiacenti, stiamo apportando un miglioramento al sistema, abbiamo incorporato nuove funzionalità e alcuni aggiornamenti di sicurezza, dacci ancora qualche minuto per finire. Presto potrai goderteli.",
    WENT_WRONG: "Qualcosa è andato storto, riprova",
    BLANK_IMAGE_UPLOAD_ERROR: "Impossibile caricare un'immagine vuota",
    ERROR_DUPLICATED_EMAIL: "Esiste già un utente con la stessa email",
    ERROR_DOCUMENT_NOT_SIGNED: "Documento non firmato",
    ERROR_DOCUMENT_NOT_FOUND: "Documento non trovato",
    ERROR_DOCUMENT_DELETED: "Il documento è stato cancellato dal mittente",
    ERROR_API: "Errore API",
    ERROR_DOCUMENT_SIZE_LARGE: "Il formato del documento è troppo grande",
    ERROR_FILE_SIZE_LARGE: "La dimensione del file è troppo grande",
    ERROR_NOT_READY_SIGN: "Documento non pronto per la firma",
    ERROR_ALREADY_IN_PROCESS: "Il documento è già in elaborazione",
    ERROR_GETTING_SIGNED_DOCUMENT: "Ottenere il documento firmato non è riuscito",
    ERROR_INVALID_OTP: "OTP non valido",
    ERROR_DOCUMENT_ALREADY_FAILED: "Lo stato del documento era già fallito",
    ERROR_NOT_READY_OTP: "Non pronto per inviare OTP, crea prima una richiesta",
    ERROR_NO_PERMISSION: "Non hai il permesso",
    ERROR_EMAIL_NOT_EXIST: "L'email non esiste",
    ERROR_INVALID_CREDENTIAL: "L'e-mail o la password non sono valide",
    ERROR_WRONG_PASSWORD: "Password errata",
    ERROR_IP_DIFF: "Rilevato problema di sicurezza",
    ERROR_USER_NOT_FOUND: "Utente non trovato",
    ERROR_CODE_EXPIRED: "Il codice di verifica è scaduto",
    ERROR_INVALID_VERIFICATION_CODE: "Codice di verifica non valido",
    ERROR_ALREADY_SIGNED_REJECTED: "Il documento è già stato firmato o rifiutato",
    ERROR_NO_CERTIFICATION: "Nessun certificato",
    ERROR_INVALID_GUID: "GUID non valido",
    ERROR_SUBSCRIPTION_EXPIRED: "Il tuo abbonamento è scaduto",
    ERROR_NOT_ALLOWED_TO_USE_OPTION: "Non sei autorizzato a utilizzare questa opzione con il tuo piano",
    ERROR_TEMPLATE_NOT_FOUND: "Il modello non esiste",
    ERROR_NO_STRIPE_CUSTOMER: "Non sei iscritto a Stripe",
    ERROR_NO_CHAT_ADMIN:
        "Non sei un amministratore. Non hai l'autorizzazione per recuperare l'elenco degli amministratori",
    ERROR_NO_STORAGE:
        "Hai esaurito il numero di archiviazione o documento, aggiorna il tuo piano o contatta il supporto",
    ERROR_INVALID_REQUEST_DATA: "I dati della richiesta non sono validi, controlla la tua richiesta e riprova",
    ERROR_NO_DOCUMENT_NUMBER:
        "Questa funzione è disabilitata perché hai esaurito le firme, acquista più documenti o aggiorna il tuo piano",
    ERROR_NOT_ALLOWED_TO_CREATE_SIGN_FLUX:
        "Non sei autorizzato a creare un flusso di segni, contatta il tuo amministratore",
    INVALID_DOCUMENT_STATUS: "Il documento non è disponibile per essere avviato alla firma",
    ERROR_NOT_ALLOWED_TO_REGISTER_DEVICE:
        "Non sei autorizzato a utilizzare la funzione del dispositivo, aggiorna il tuo piano o contatta il supporto",
    ERROR_TABLET_NOT_FOUND: "Il tablet con questo ID dispositivo non esiste o non sei il proprietario",
    ERROR_NO_SIGNATURE_IMAGE: "Nessuna immagine della firma",
    ERROR_DOCUMENT_DELETED_OR_SIGN_CANCELLED: "Il documento è stato eliminato o il flusso di segni è stato annullato",
    ERROR_NOT_ALLOWED_USE_CERTIFICATE:
        "Non sei autorizzato a utilizzare la funzionalità del certificato cloud nel tuo piano, aggiornalo",
    ERROR_NOT_ADMIN: "Non sei l'amministratore dell'account",
    ERROR_CONTACT_NOT_FOUND: "Contatto non trovato",
    ERROR_INVALID_DISCOUNT_CODE: "Codice sconto non valido",
    ERROR_NOT_DOCUMENT_OWNER: "Non sei il proprietario di questo documento",
    ERROR_NOT_ALLOWED_TO_USE_TEMPLATE:
        "Non sei autorizzato a utilizzare la funzionalità dei modelli intelligenti, aggiorna il tuo piano o contatta il supporto",
    ERROR_NOT_ALLOWED_TO_USE_SMART_FLUX:
        "Non sei autorizzato a utilizzare la funzionalità di flusso intelligente, aggiorna il piano o contatta il supporto",
    ERROR_NOT_DOCUMENT_SENDER: "Non sei il mittente di questo documento, non hai il permesso",
    ERROR_NOT_ALLOWED_LOCAL_CERTIFICATE:
        "Non sei autorizzato a utilizzare la funzionalità del certificato locale, aggiorna il tuo piano o contatta il supporto",
    ERROR_SIGN_FLOW: "Flusso di segni non valido",
    ERROR_SIGN_METHOD: "Metodo del segno non valido",
    ERROR_SIGN_FLOW_LEGALE:
        "Non sei autorizzato a inviare documenti a un altro utente legale, aggiorna il tuo piano o contatta il supporto",
    ERROR_SIGN_FLOW_APPROVE:
        "Non sei autorizzato a inviare documenti per l'approvazione, aggiorna il tuo piano o contatta il supporto",
    ERROR_SIGN_FLOW_TABLET:
        "Non è consentito utilizzare il flusso del dispositivo mobile, si prega di aggiornare il piano o contattare l'assistenza",
    ERROR_SIGNATURE_NUMBER_SMS:
        "Non hai un numero di firma sufficiente per il segno SMS remoto o non sei autorizzato a utilizzare questo metodo di segno",
    ERROR_SIGNATURE_NUMBER_NO_SMS:
        "Non hai un numero di firma sufficiente per il segno remoto o non sei autorizzato a utilizzare questo metodo di segno",
    ERROR_SIGNATURE_NUMBER_LOCAL_CERTIFICATE:
        "Non hai un numero di firma sufficiente per il segno del certificato locale o non sei autorizzato a utilizzare questo metodo di segno",
    ERROR_SIGNATURE_NUMBER_CERTIFICATION:
        "Non hai un numero di firma sufficiente per la firma del certificato Cloud o non sei autorizzato a utilizzare questo metodo di firma",
    ERROR_SIGNATURE_NUMBER_STAMP:
        "Non hai un numero di firma sufficiente per il segno Timbro o non sei autorizzato a utilizzare questo metodo di segno",
    ERROR_SIGNATURE_NUMBER_TABLET_SIGN:
        "Non hai un numero di firma sufficiente per il segno del dispositivo mobile o non sei autorizzato a utilizzare questo metodo di segno",
    ERROR_SIGNATURE_NUMBER_VIDEO_VALIDATION:
        "Non hai un numero sufficiente per la verifica video o non sei autorizzato a utilizzare questo metodo di verifica",
    ERROR_SIGNATURE_NUMBER_SELFIE_VALIDATION:
        "Non hai un numero sufficiente per la verifica del selfie o non sei autorizzato a utilizzare questo metodo di verifica",
    ERROR_SIGNATURE_NUMBER_ID_VALIDATION:
        "Non hai un numero sufficiente per la verifica dell'ID o non sei autorizzato a utilizzare questo metodo di verifica",
    ERROR_LEGALE_NOT_FOUND: "L'utente Legale, a cui invierai il documento a firmare, non esiste",
    ERROR_DEVICE_NOT_FOUND: "Il dispositivo mobile a cui invierai il documento per firmare non esiste",
    ERROR_SIGNATURE_IMAGE_POSITION: "Posizione dell'immagine della firma non valida",
    ERROR_NOT_ALLOWED_SECONDARY_USER:
        "Non sei l'amministratore dell'account o non sei autorizzato a utilizzare la funzione utente secondaria",
    ERROR_COUPON_ALREADY_USED: "Hai già utilizzato questo codice sconto",
    ERROR_LINK_INVALID: "Questo collegamento non è valido",
    ERROR_LINK_EXPIRED: "Questo collegamento è scaduto",
    ERROR_NOT_OWNER: "Non sei il proprietario",
    ERROR_CONTACT_DUPLICATED: "Esiste già un contatto con la stessa email",
    ERROR_DOCUMENT_SIGN_EXPIRED: "Il flusso dei segni del documento è scaduto, non puoi continuare questo flusso",
    ERROR_SMART_FORM_LINK_EXPIRED: "Questo collegamento è scaduto e non è più disponibile",
    ERROR_FOLDER_CREATE_NOT_ALLOWED: "Non sei autorizzato a creare una cartella",
    ERROR_FOLDER_RENAME_NOT_ALLOWED: "Non sei autorizzato a rinominare la cartella",
    ERROR_FOLDER_DELETE_NOT_ALLOWED: "Non sei autorizzato a eliminare la cartella",
    ERROR_DOCUMENT_RENAME_NOT_ALLOWED: "Non sei autorizzato a rinominare il documento",
    ERROR_DOCUMENT_DELETE_NOT_ALLOWED: "Non sei autorizzato a eliminare il documento",
    ERROR_NOT_AVAILABLE_TO_UPLOAD_TO_FOLDER:
        "Non disponibile per caricare il documento nella cartella corrente, è pieno",
    ERROR_INVALID_REMOTE_PATH: "Il percorso remoto non è valido",
    ERROR_CSV_IS_NOT_MATCHED: "Il formato CSV non corrisponde all'aggiornamento",
    ERROR_DUPLICATED_FOLDER_NAME: "Nome della cartella duplicato",
    ERROR_DOCUMENT_BROKEN: "Il documento è rotto o danneggiato, non è possibile avviare il flusso dei segni",

    SUCCESS_PURCHASE: "L'acquisto è andato a buon fine",
    SUCCESS_SENT_OTP: "OTP è stato inviato al tuo telefono, controlla il tuo SMS",
    SUCCESS_OTP: "OTP inviato con successo",
    SUCCESS_SUBSCRIBED: "Iscritto con successo",
    SUCCESS_RESENT: "Reinviato con successo",
    SUCCESS_SIGNER_REMOVED: "Il firmatario è stato rimosso con successo",
    SUCCESS_DOCUMENT_SENT: "Documento inviato con successo",
    SUCCESS_DOCUMENT_SENT_TO_EMAIL: "Il documento da firmare è stato inviato all'e-mail",
    SUCCESS_DOCUMENT_RESENT: "Documento reinviato con successo",
    SUCCESS_SIGNED: "Firmato con successo",
    SUCCESS_DOCUMENT_SHARED: "Documento condiviso con successo",
    SUCCESS_UPDATE_PROFILE: "Aggiorna il profilo con successo",
    SUCCESS_UPLOAD_USER_VIDEO: "Carica video con successo",
    SUCCESS_UPLOAD_SIGNATURE_IMAGE: "Carica l'immagine della firma con successo",
    SUCCESS_UPLOAD_CERTIFICATION: "Certificato caricato con successo",
    SUCCESS_UPDATED_CERTIFICATION: "Password aggiornata con successo",
    SUCCESS_REMOVED_CERTIFICATION: "Certificato rimosso con successo",
    SUCCESS_UPDATED_CONFIGURATION: "Aggiorna la configurazione con successo",
    SUCCESS_UPDATED_PASSWORD: "Aggiorna la password con successo",
    SUCCESS_ADD_SIGNATURE: "Aggiungi il numero della firma con successo",
    SUCCESS_CREATED_USER: "Utente creato con successo",
    SUCCESS_UPDATED_USER: "Utente aggiornato con successo",
    SUCCESS_DELETED_USER: "Utente eliminato con successo",
    SUCCESS_CREATED_CONTACT: "Contatto creato con successo",
    SUCCESS_UPDATED_CONTACT: "Contatto aggiornato con successo",
    SUCCESS_DELETED_CONTACT: "Contatto cancellato con successo",
    SUCCESS_DELETED_CONTACTS: "Contatti eliminati con successo",
    SUCCESS_IMPORTED_CONTACT: "Contatto importato con successo",
    SUCCESS_UPLOADED_DOCUMENT: "Documento caricato con successo",
    SUCCESS_UPLOADED_FILE: "File caricati con successo",
    SUCCESS_UPDATED_DOCUMENT: "Documento aggiornato con successo",
    SUCCESS_DELETED_DOCUMENT: "Documento eliminato con successo",
    SUCCESS_REJECTED: "Rifiutato con successo",
    SUCCESS_UPLOADED_ATTACHED_FILE: "File allegato caricato con successo",
    SUCCESS_DELETED_ATTACHED_FILE: "File allegato eliminato con successo",
    SUCCESS_PAUSED_FLOW: "Flusso sospeso con successo",
    SUCCESS_SIGNED_DOCUMENT: "Documento firmato con successo",
    SUCCESS_PLAY_FLOW: "Il flusso è stato riprodotto di nuovo con successo",
    SUCCESS_SIGN_PROCESS_STARTED: "Discussione dei segni iniziata",
    SUCCESS_APPROVED_DOCUMENT: "Documento approvato con successo",
    SUCCESS_APPLIED: "Applicato con successo",
    SUCCESS_SIGN_BACKGROUND: "Il processo di firma è in background, riceverai lo stato tramite la tua email",
    SUCCESS_DELETED_TABLET: "Tablet eliminato correttamente",
    SUCCESS_CANCEL_SUBSCRIPTION: "Abbonamento annullato con successo",
    SUCCESS_CREATE_CUSTOMIZE_PLAN: "Ti contatterai presto",
    SUCCESS_LINK_COPIED_TO_CLIPBOARD: "Il link del modulo è stato copiato negli appunti",
    SUCCESS_CSV_LINK_TEMPLATE: "Il CSV è collegato correttamente al modello",
    SUCCESS_CSV_WORKFLOW_UPDATE: "Il flusso di lavoro CSV è stato aggiornato correttamente",
    SUCCESS_CSV_ALARM_UPDATE: "L'allarme CSV è stato aggiornato correttamente",
    SUCCESS_CSV_WORKFLOW_LAUNCH: "Il flusso di lavoro CSV è stato avviato correttamente",
    SUCCESS_SAVED: "Salvato con successo",

    CONFIRM_REMOVE_CONTACT: "Sei sicuro di rimuovere questo contatto?",
    CONFIRM_REMOVE_CONTACTS: "Sei sicuro di rimuovere questi contatti?",
    CONFIRM_SET_AS_EXTERNAL_CONTACTS: "Sei sicuro di impostare questi contatti come utenti esterni?",
    CONFIRM_APPROVE_DOCUMENT: "Sei sicuro di approvare questo documento?",
    CONFIRM_REPLACE_VIDEO_VALIDATION: "Questo documento ha già la convalida video, sei sicuro di sostituirlo?",
    CONFIRM_REPLACE_SELFIE_VALIDATION: "Questo documento ha già l'immagine selfie, sei sicuro di sostituirla?",
    CONFIRM_REPLACE_ID_VALIDATION: "Questo documento ha già l'immagine del documento, sei sicuro di sostituirla?",
    CONFIRM_REMOVE_CERTIFICATION: "Sei sicuro di rimuovere questa certificazione?",
    CONFIRM_REMOVE_SIGNER: "Sei sicuro di rimuovere questo firmatario?",
    CONFIRM_DELETE_DOCUMENT: "Sei sicuro di eliminare questo documento?",
    CONFIRM_NOTIFICATION_MULTIPLE: "Sei sicuro di inviare una notifica a questi firmatari?",
    CONFIRM_DELETE_DOCUMENT_MULTIPLE: "Eliminare i documenti selezionati?",
    CONFIRM_PAUSE_DOCUMENT: "Sei sicuro di mettere in pausa il flusso di firma di questo documento?",
    CONFIRM_END_SIGN_FLOW: "Sei sicuro di terminare questo flusso di segnali?",
    CONFIRM_CONTINUE_FLOW: "Sei sicuro di continuare questo flusso di segnali?",
    CONFIRM_DELETE_FILE: "Sei sicuro di eliminare questo file?",
    CONFIRM_REMOVE_USER: "Sei sicuro di rimuovere questo utente?",
    CONFIRM_REMOVE_TABLET: "Sei sicuro di rimuovere questo tablet?",
    CONFIRM_REPLACE_USER_VIDEO: "Sei sicuro di sostituire il video?",
    CONFIRM_MAKE_AS_ADMIN: "Sei sicuro di impostare questo utente come amministratore?",
    CONFIRM_REMOVE_FLUX: "Sei sicuro di eliminare questo flusso?",
    CONFIRM_SEND_DOCUMENT_USE_FLUX: "Sei sicuro di inviare questi documenti utilizzando questo flusso?",
    CONFIRM_REMOVE_TEMPLATE: "Sei sicuro di eliminare questo modello?",
    CONFIRM_DUPLICATE_TEMPLATE: "Duplicare questo modello?",
    CONFIRM_CANCEL_SUBSCRIPTION: "Sei sicuro di annullare l'abbonamento?",
    CONFIRM_MAKE_AS_CUSTOMIZED_PLAN: "Sei sicuro di assegnare un piano su misura a questo cliente?",
    CONFIRM_DELETE_FOLDER: "Sei sicuro di eliminare questa cartella?",
    CONFIRM_REMOVE_FORM: "Sei sicuro di eliminare questo modulo?",
    CONFIRM_DELETE_ALARM: "Sei sicuro di eliminare questo allarme?",
    CONFIRM_DELETE: "Sei sicuro di eliminare?",
    CONFIRM_LAUNCH_WORKFLOW: "Sei sicuro di voler avviare Workflow?",
    CONFIRM_SAVE_TEMPLATE: "Salvando creerai una nuova versione, sei sicuro di farlo?",
    CONFIRM_LAUNCH_MULTIPLE_CSVS_WORKFLOW: "Vuoi avviare il flusso di lavoro di questi CSV?",
    CONFIRM_UNGROUP_TEMPLATES: "Sei sicuro di separare questi modelli?",

    success: "Successo",
    error: "Errore",
    warning: "Avvertimento",

    // LAYOUT
    footer1: "legale.io è una società Despapeliza Llc. 7345 W Sand Lake RD STE 210, OFC 6837, Orlando, FL, USA",
    footer2: "Tutti i diritti riservati legale",
    signOut: "Disconnessione",

    // LOGIN
    login: "Login",
    email: "E-mail",
    password: "Parola d'ordine",
    fieldRequired: "Questo campo è obbligatorio",
    fieldInvalid: "valore non valido",
    fileLengthError: "Il nome file deve contenere meno di {{limit}} caratteri",
    submit: "Invia",

    // HOME
    searchDocumentByNationalIDNumber: "Cerca il tuo documento in base al numero di carta d'identità nazionale",
    sign: "Cartello",
    noSearchResult: "Nessun documento trovato con le tue informazioni",
    searchTip:
        "Per individuare il documento in attesa della tua firma, è necessario il tuo numero di identificazione. Questo identificatore univoco garantisce che ti abbiniamo accuratamente al documento corretto. Tieni a portata di mano il tuo numero di identificazione e inseriscilo quando richiesto.",
    searchTipNoResult: "Trova il tuo documento contrattuale da firmare con il tuo numero di carta d'identità nazionale",
    fileName: "Nome del file",
    subject: "Materia",
    description: "Descrizione",
    backToSearch: "Torna alla ricerca",

    // SIGN
    pdfPaginationDescription: "Pagina {{pageNumber}} di {{totalPage}}",
    signatureImage: "Immagine della firma",
    cancel: "Annulla",
    back: "Di ritorno",
    next: "Prossimo",
    sendCodeToMyEmail: "Invia il codice alla mia email",
    sendCodeToMyPhone: "Invia il codice al mio telefono",
    verifyDescription:
        "Per garantire la sicurezza delle tue informazioni, abbiamo bisogno di verificare la tua identità. Puoi scegliere di ricevere un codice di verifica tramite telefono o e-mail. Seleziona il metodo preferito.",
    selfieDescription:
        "Nell'ambito del nostro impegno volto a garantire il massimo livello di sicurezza e protezione dell'identità, richiediamo un'ultima prova per confermare la tua identità.",
    signatureImageDrawingTip: "Non disegnare la tua firma sul codice QR",
    signatureImageDescription: "Per favore disegna la tua immagine della firma sopra la scatola",
    clearSignature: "Firma chiara",
    note: "Nota",
    sendCode: "Manda il codice",
    verifyCodeDescription:
        "Vogliamo essere sicuri che sia davvero tu. Per verificare ulteriormente la tua identità, inserisci il codice di verifica che è stato inviato a {{destination}}.",
    confirm: "Confermare",
    resendCode: "Codice di rispedizione",
    code: "Codice",
    phone: "Telefono",
};
