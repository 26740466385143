import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "antd";
import {
    FastBackwardOutlined,
    FastForwardOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
} from "@ant-design/icons";

import "./styles.scss";

const propTypes = {
    numPages: PropTypes.number.isRequired,
    pageNumber: PropTypes.number.isRequired,
    setPageNumber: PropTypes.func.isRequired,
};

const PdfPagination = ({ numPages, pageNumber, setPageNumber }) => {
    const { t } = useTranslation();

    return (
        <div className="legale-pdf-pagination">
            <Button
                shape="circle"
                className="no-border button-green"
                icon={<FastBackwardOutlined />}
                disabled={pageNumber === 1}
                onClick={() => setPageNumber(1)}
            />
            <Button
                shape="circle"
                className="no-border button-green"
                icon={<StepBackwardOutlined />}
                disabled={pageNumber === 1}
                onClick={() => setPageNumber(pageNumber - 1)}
            />
            <p>{t("pdfPaginationDescription", { pageNumber: pageNumber, totalPage: numPages })}</p>
            <Button
                shape="circle"
                className="no-border button-green"
                icon={<StepForwardOutlined />}
                disabled={pageNumber === numPages}
                onClick={() => setPageNumber(pageNumber + 1)}
            />
            <Button
                shape="circle"
                className="no-border button-green"
                icon={<FastForwardOutlined />}
                disabled={pageNumber === numPages}
                onClick={() => setPageNumber(numPages)}
            />
        </div>
    );
};

PdfPagination.prototype = propTypes;

export default PdfPagination;
