import { updateState } from "../utils";
import * as actionTypes from "../actionTypes";

const initialState = {
    theme: localStorage.getItem("theme") ? localStorage.getItem("theme") : "light",
    loading: false,
    language: !!localStorage.getItem("i18nextLng")
        ? ["en", "es", "pt", "ca", "fr", "it", "zh"].indexOf(localStorage.getItem("i18nextLng").split("-")[0]) !== -1
            ? localStorage.getItem("i18nextLng").split("-")[0]
            : "en"
        : "en",
    message: {
        type: null,
        content: null,
    },
};

const updateTheme = (state, action) => {
    return updateState(state, {
        theme: action.theme,
    });
};

const updateLoading = (state, action) => {
    return updateState(state, {
        loading: action.loading,
    });
};

const updateLanguage = (state, action) => {
    return updateState(state, {
        language: action.language,
    });
};

const updateMessage = (state, action) => {
    return updateState(state, {
        message: action.message,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_THEME:
            return updateTheme(state, action);
        case actionTypes.UPDATE_LOADING:
            return updateLoading(state, action);
        case actionTypes.UPDATE_LANGUAGE:
            return updateLanguage(state, action);
        case actionTypes.UPDATE_MESSAGE:
            return updateMessage(state, action);
        default:
            return state;
    }
};

export default reducer;
